import React, { useContext, useEffect , forwardRef} from "react";
import { FLISContext } from "../context/FLISContext";
import { FormHookCheckbox, FormHookInput, FormHookSelect } from "./FormHook/ReactFormHookFields";
import { Break, FuelTypes, NEW_LICENCE, VesselOwnerships } from "./DataSource";
import { EngineDetails } from "./EngineDetails";


export const VesselDetails = forwardRef(({watch , formState,control, register, onSubmit, setValue, ...props }, ref) => {


    const {errors, isDirty, isValid} = formState;




    const {selectedMap} = useContext(FLISContext);

    const knownEngineNumber = watch('fishing_vessel_details.engine_serial_number_available');
    console.log(knownEngineNumber);
    useEffect(() => {

      if(selectedMap.fishing_vessel_details){
        setValue('fishing_vessel_details', selectedMap.fishing_vessel_details);
      }

    }, [selectedMap.fishing_vessel_details]);

    return (
      <div className="form-container">
        <fieldset>
          <form onSubmit={onSubmit}>
            <div className="shadow p-3 mb-3 bg-white rounded">
              <div className="d-flex flex-row form-heading">
                <div
                  style={{ marginRight: "10px" }}
                >{`${"Vessel Details"}`}</div>
              </div>
              <div className="d-flex-wrap flex-row justify-content-start">
                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.vessel_identification_number
                      ?.message
                  }
                  label="Vessel Identification / Decal Number"
                  placeholder="Vessel Identification / Decal Number"
                  regFieldName={`fishing_vessel_details.vessel_identification_number`}
                  validations={{
                    maxLength: { value: 100, message: "Max length is 100" },
                  }}
                  isRequired={
                    selectedMap.type_of_transaction === NEW_LICENCE
                      ? false
                      : "This is required"
                  }
                  isDisabled={true}
                  toAddOptionalText={false}
                  helpText="This is the NFA Vessel Reg. #"
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.name_of_vessel?.message
                  }
                  label="Name of Vessel"
                  placeholder="Name of Vessel"
                  regFieldName={`fishing_vessel_details.name_of_vessel`}
                  type="text"
                  validations={{
                    maxLength: { value: 100, message: "Max length is 100" },
                  }}
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.maj_registration_number
                      ?.message
                  }
                  label="MAJ Registration #"
                  placeholder="MAJ Registration #"
                  regFieldName={`fishing_vessel_details.maj_registration_number`}
                  type="text"
                  validations={{
                    maxLength: { value: 100, message: "Max length is 100" },
                  }}
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.home_base_port?.message
                  }
                  label="Home / Base Port"
                  placeholder="Home / Base Port"
                  regFieldName={`fishing_vessel_details.home_base_port`}
                  type="text"
                  validations={{
                    maxLength: { value: 100, message: "Max length is 100" },
                  }}
                />

                <FormHookSelect
                  label="Vessel Ownership"
                  register={register}
                  regFieldName={`fishing_vessel_details.vessel_ownership`}
                  options={VesselOwnerships}
                  error={
                    errors?.fishing_vessel_details?.vessel_ownership?.message
                  }
                />

                <FormHookSelect
                  // label="Vessel has Valid Seagoing Certificate"
                  label="Vessel has Small Vessel Safety Certificate"
                  register={register}
                  regFieldName={`fishing_vessel_details.has_valid_seagoing_certificate`}
                  options={["Yes", "No"]}
                  error={
                    errors?.fishing_vessel_details
                      ?.has_valid_seagoing_certificate?.message
                  }
                  parentClass="individual-field"
                />
              </div>

              <div className="d-flex-wrap flex-row justify-content-start">
                {/* <FormHookCheckbox
                      label="Vessel Class:"
                      register={register}
                      regFieldName="fishing_vessel_details.vessel_class"
                      options={[
                        "Non-Decked (Canoe)",
                        "Partial Decked",
                        "Full Descked (Trawler)",
                        "Yacht / Sail",
                      ]}
                      error={
                        errors?.fishing_vessel_details?.vessel_class?.message
                      }
                    /> */}

                <FormHookCheckbox
                  label="Hull Material:"
                  register={register}
                  regFieldName="fishing_vessel_details.hull_material"
                  options={["Fiberglass", "Steel", "Wood", "Other"]}
                  error={errors?.fishing_vessel_details?.hull_material?.message}
                  isMulti={true}
                />

                <Break />
                <FormHookInput
                  register={register}
                  error={errors?.fishing_vessel_details?.hull_color?.message}
                  label="Hull Colour:"
                  regFieldName="fishing_vessel_details.hull_color"
                  placeholder="Hull Colour"
                  type="text"
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.length_in_meters?.message
                  }
                  label="Length (Meters)"
                  regFieldName="fishing_vessel_details.length_in_meters"
                  placeholder="Length (Meters)"
                  type="number"
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.depth_in_meters?.message
                  }
                  label="Depth (Meters)"
                  regFieldName="fishing_vessel_details.depth_in_meters"
                  placeholder="Depth (Meters)"
                  type="number"
                />

                <FormHookInput
                  register={register}
                  error={
                    errors?.fishing_vessel_details?.beam_in_meters?.message
                  }
                  label="Beam (Meters)"
                  regFieldName="fishing_vessel_details.beam_in_meters"
                  placeholder="Beam (Meters)"
                  type="number"
                />

                <FormHookInput
                  register={register}
                  error={errors?.fishing_vessel_details?.tonnage_mt?.message}
                  label="Tonnage (Weight)"
                  regFieldName="fishing_vessel_details.tonnage_mt"
                  placeholder="Tonnage (Weight)"
                  type="number"
                />

                <FormHookInput
                  register={register}
                  error={errors?.fishing_vessel_details?.max_crew_size?.message}
                  label="Max Crew Size"
                  regFieldName="fishing_vessel_details.max_crew_size"
                  placeholder="Max Crew Size"
                  type="number"
                />

                <FormHookCheckbox
                  label="Range:"
                  error={errors?.fishing_vessel_details?.range?.message}
                  register={register}
                  regFieldName="fishing_vessel_details.range"
                  options={["Coastal", "Offshore"]}
                  isMulti={true}
                />

                <Break />
                
                <FormHookCheckbox
                  label="Fuel Type:"
                  error={errors?.fishing_vessel_details?.fuel_type?.message}
                  register={register}
                  regFieldName="fishing_vessel_details.fuel_type"
                  options={FuelTypes}
                  isMulti={false}
                />

                <Break />

                <div className="">
                  <label className="individual-field-margin mt-2">
                    Engine Details:
                  </label>

                  <EngineDetails
                    register={register}
                    formState={formState}
                    watch={watch}
                    control={control}
                    onSubmit={onSubmit}
                    setValue={setValue}
                  ></EngineDetails>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    );
});
