import { AMENDMENT, ARTISANAL_FISHING, CARRIER_FISHING, COMMERCIAL, INDUSTRIAL_FISHING, NEW_LICENCE, RECREATIONAL, RENEWAL } from "../components/DataSource";
import { INDUSTRIAL_VESSEL_CREW_MEMBER, MORANT_PEDRO, NEW_PERMIT, TEMPORARY_PERMIT, TOURNAMENT_HOSTING } from "../containers/PermitLicence/PermitDataSource";


const trnDocument = {
    id: 1001,
    key: 'tax_registration_number',
    name: "Tax Registration Number",
    isMandatory: false,
    
};

const fishersId =  {
    id: 1002,
    key: 'fishers_id',
    name: "Fisher's ID",
    isMandatory: true
};

const passportSizedPhotograph = {
    id: 1003,
    key: 'passport_sized_photograph',
    name: "Passport-sized Photograph",
    isMandatory: true
};

const declarationOfKnowledgeForm = {
    id: 1004,
    key: 'declaration_of_knowledge_form',
    name: "Declaration of Knowledge Form",
    isMandatory: true
};

const copyOfPassportForForeigner = {
    id: 1005,
    key: 'copy_of_passport_for_foreigner',
    name: "Copy of Passport for Foreigner",
    isMandatory: false,
    condition: ({domicile}) => domicile === 'Foreign'
};

const individualLicenceToFish = {
    id: 1006,
    key: 'individual_licenceto_fish',
    name: "Individual Licence to Fish",
    isMandatory: true
}

const workPermitForForeigner = {
    id: 1007,
    key: 'work_permit_for_foreigner',
    name: "Work Permit for Foreigner",
    isMandatory: true
}

const foodHandlersPermitForIndustrialCrew = {
    id: 1008,
    key: 'food_handlers_permits_for_industrial_crew',
    name: "Food Handlers Permits for Industrial Crew",
    isMandatory: true
}

const pooMAJRegCertificate = {
    id: 1014,
    key: 'proof_of_ownership_maj_reg_certificate',
    name: "Proof of Ownership: MAJ Registration Certificate",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const majSmallVesselSafetyCertificate = {
    id: 1015,
    key: "maj_small_vessel_safety_certificate",
    name: "MAJ Small Vessel Safety Certificate",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const pooBillofSaleTransferOwnershipForm = {
    id: 1016,
    key: "proof_of_ownership_bill_of_sale_transfer",
    name: "Proof of Ownership: Bill of Sale/Transfer of Ownership Form",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const pooLetterFromJP = {
    id: 1017,
    key: "proof_of_ownership_letter_from_jp",
    name: "Proof of Ownership: Letter from JP stating that owner has built vessel",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const proofReceiptForVesselPurchase = {
    id: 1018,
    key: "proof_receipt_for_vessel_purchase",
    name: "Proof Receipt for Vessel Purchase",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const receiptForItemsUsedToConstructVessel = {
    id: 1019,
    key: "receipt_for_items_used_to_construct_vessel",
    name : "Receipt for Items Used to Construct Vessel",
    isMandatory: false,
    isInGroup: true,
    group_key: 'proof_of_ownership'
}

const currentPictureOfApplicant = {
    id: 1020,
    key: "current_picture_of_applicant",
    name : "Current Passport-Sized Picture of Applicant",
    isMandatory: true,
    accept: ".jpg, .jpeg, .png",
    bpmClassNames: "FLIS_Picture"
}

const detailedResearchProposal = {
    id: 1021,
    key: "detailed_research_proposal",
    name : "Detailed Research Proposal",
    isMandatory: true,
}

const broadsideVessel = {
    id: 1022,
    key: "broad_side_view_vessel",
    name : "Broadside (Side) View",
    isMandatory: false,
    accept: ".jpg, .jpeg, .png",
    bpmClassNames: "FLIS_VesselSideView"
}


const bowFrontViewVessel = {
    id: 1023,
    key: "bow_front_view_vessel",
    name : "Bow (Front) View",
    isMandatory: false,
    accept: ".jpg, .jpeg, .png",
    bpmClassNames: "FLIS_vesselFront"
}

const sternBackViewVessel = {
  id: 1024,
  key: "stern_back_view_vessel",
  name : "Stern (Back) View",
  isMandatory: false,
  accept: ".jpg, .jpeg, .png",
  bpmClassNames: "FLIS_vesselBack"
}

const bowFrontSternBackViewVessel = {
  id: 1025,
  key: "bow_front_stern_back_view_vessel",
  name : "Bow (Front) or Stern (Back) View",
  isMandatory: false,
  accept: ".jpg, .jpeg, .png",
  // to be checked with Ahmed
  // bpmClassNames: "FLIS_vesselFrontBack"
  bpmClassNames: "FLIS_VesselFrontorBackView"
};


const previousVesselLicence = {
  id: 1026,
  key: "previous_vessel_licence",
  name: "Previous Vessel Licence",
  isMandatory: false,
  isInGroup: true,
  group_key: 'proof_of_ownership'
}


const baseDocs = [
    // {
    //     id: 1009,
    //     key: 'driver_licence',
    //     name: <><div><b>Valid Identification:</b></div>

        
    //     <div>Driver's Licence</div>
    //     <div>Passport</div>
    //     <div>NIDS ID</div>
    //     <div>Birth Certificate plus certified photograph (if no ID)</div>
    //     <div>Statutory Declaration of Date of Birth (If no ID or birth certificate)</div>
    //     </>,
    //     isMandatory: true,
    //     isComponent: true
    // },
    // {
    //     id: 1010,
    //     key: 'passport',
    //     name: "Passport",
    //     isMandatory: true
    // },
    // {
    //     id: 1011,
    //     key: 'nids_id',
    //     name: "NIDS ID",
    //     isMandatory: true
    // },
    // {
    //     id: 1012,
    //     key: 'birth_certificate_photograph',
    //     name: "Birth Certificate plus certified photograph (if no IID)",
    //     isMandatory: true
    // },
    // {
    //     id: 1013,
    //     key: 'statutory_certificate',
    //     name: "Statutory Declaration of Date of Birth (If no ID or birth certificate)",
    //     isMandatory: true
    // },

];


export const groupDocsInfo = {
    proof_of_ownership: {
        isMandatory: true,
        name: "Proof of Ownership (Atleast one document is mandatory)",
        atleastOne: true,
        all: false
    }
}
export const documentConfig = {
  APPLICATION_FOR_LICENCE: {
    [NEW_LICENCE]: [
      ...baseDocs,
      // fishersId,
      // trnDocument,
      // passportSizedPhotograph, removed on 18 jun and clubbed with current picture of applicant
      // declarationOfKnowledgeForm,
      copyOfPassportForForeigner,
      currentPictureOfApplicant,
    ],
    [RENEWAL]: [
      fishersId,
      // trnDocument,
      // declarationOfKnowledgeForm,
      currentPictureOfApplicant,
    ],
    [AMENDMENT]: [currentPictureOfApplicant],
  },
  APPLICATION_FOR_FISHING_VESSEL: {
    // to be added after resolving doubt

    [COMMERCIAL]: {
      [ARTISANAL_FISHING]: {
        [NEW_LICENCE]: [
          ...baseDocs,
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
          copyOfPassportForForeigner,
        ],
        [RENEWAL]: [
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
          copyOfPassportForForeigner,
        ],
      },
      [INDUSTRIAL_FISHING]: {
        [NEW_LICENCE]: [
          ...baseDocs,
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
        ],
        [RENEWAL]: [
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
        ],
      },
      [CARRIER_FISHING]: {
        [NEW_LICENCE]: [
          ...baseDocs,
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
        ],
        [RENEWAL]: [
          fishersId,
          individualLicenceToFish,
          broadsideVessel,
          // bowFrontViewVessel,
          // sternBackViewVessel,
          bowFrontSternBackViewVessel,
          pooMAJRegCertificate,
          majSmallVesselSafetyCertificate,
          pooBillofSaleTransferOwnershipForm,
          pooLetterFromJP,
          proofReceiptForVesselPurchase,
          receiptForItemsUsedToConstructVessel,
          previousVesselLicence,
        ],
      },
    },
    [RECREATIONAL]: {
      [NEW_LICENCE]: [
        ...baseDocs,
        fishersId,
        individualLicenceToFish,
        broadsideVessel,
        // bowFrontViewVessel,
        // sternBackViewVessel,
        bowFrontSternBackViewVessel,
        pooMAJRegCertificate,
        majSmallVesselSafetyCertificate,
        pooBillofSaleTransferOwnershipForm,
        pooLetterFromJP,
        proofReceiptForVesselPurchase,
        receiptForItemsUsedToConstructVessel,
        previousVesselLicence,
        copyOfPassportForForeigner,
      ],
      [RENEWAL]: [
        fishersId,
        individualLicenceToFish,
        broadsideVessel,
        // bowFrontViewVessel,
        // sternBackViewVessel,
        bowFrontSternBackViewVessel,
        pooMAJRegCertificate,
        majSmallVesselSafetyCertificate,
        pooBillofSaleTransferOwnershipForm,
        pooLetterFromJP,
        proofReceiptForVesselPurchase,
        receiptForItemsUsedToConstructVessel,
        previousVesselLicence,
      ],
    },
  },
  APPLICATION_FOR_PERMIT: {
    [INDUSTRIAL_VESSEL_CREW_MEMBER]: {
      [NEW_PERMIT]: [
        ...baseDocs,
        // passportSizedPhotograph, // removed and added currentPictureOfApplicant
        currentPictureOfApplicant,
        workPermitForForeigner,
        foodHandlersPermitForIndustrialCrew,
        copyOfPassportForForeigner,
      ],
      [RENEWAL]: [
        ...baseDocs,
        fishersId,
        workPermitForForeigner,
        foodHandlersPermitForIndustrialCrew,
        copyOfPassportForForeigner,
      ],
    },
    [MORANT_PEDRO]: {
      [NEW_PERMIT]: [
        fishersId,
        currentPictureOfApplicant,
        individualLicenceToFish,
      ],
      [RENEWAL]: [
        fishersId
      ]
    },
    [TEMPORARY_PERMIT]: {
      [NEW_PERMIT]: [...baseDocs,fishersId, currentPictureOfApplicant , copyOfPassportForForeigner],
      [RENEWAL]: [fishersId],
    },
    [TOURNAMENT_HOSTING]: {
      [NEW_PERMIT]: [
        currentPictureOfApplicant
      ],
      [RENEWAL]: [fishersId],
    },
  },
  APPLICATION_FOR_AQUACULTURE_FISHER: {
    // made same as fisher licence
    [NEW_LICENCE]: [
      ...baseDocs,
      copyOfPassportForForeigner,
      currentPictureOfApplicant,
    ],
    [RENEWAL]: [fishersId, currentPictureOfApplicant],
    [AMENDMENT]: [currentPictureOfApplicant],
  },
  APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: {},
  APPLICATION_FOR_AQUACULTURE_FACILITY: {},
  APPLICATION_FOR_AUTHORIZATION: [
    currentPictureOfApplicant,
    detailedResearchProposal,
  ],
  APPLICATION_QUOTA_FISHING: {},
  APPLICATION_FOR_DECLARATION: {},
  APPLICATION_FOR_LANDING_CATCH_DECLARATION: {},
  APPLICATION_FOR_GENERAL_ADMINISTRATION: {},
  APPLICATION_FOR_FISHER_WORKER_ID: [currentPictureOfApplicant],
};


export const getDocuments = (appType, data) => {
    let allDocuments = [];
    if (appType === 'APPLICATION_FOR_LICENCE'){
        allDocuments =  documentConfig[appType][data.type_of_transaction];
        // allDocuments =  documentConfig[appType]["New Licence"];
    } else if(appType === 'APPLICATION_FOR_PERMIT') {
      allDocuments =  documentConfig?.[appType]?.[data.type_of_permit]?.[data.type_of_transaction];
    } else if(appType === 'APPLICATION_FOR_AUTHORIZATION') {
        allDocuments =  documentConfig[appType]
    } else if(appType === 'APPLICATION_QUOTA_FISHING') {

    } else if(appType === 'APPLICATION_FOR_DECLARATION') {

    } else if(appType === 'APPLICATION_FOR_FISHING_VESSEL') {

        if(data.licence_type === COMMERCIAL){
            allDocuments =  documentConfig?.[appType]?.[data.licence_type]?.[data.commercial_licence_type]?.[data.type_of_transaction];
        } else if(data.licence_type === RECREATIONAL) {
            allDocuments = documentConfig?.[appType]?.[data.licence_type]?.[data.type_of_transaction];
        }
        
    } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHER') {
      allDocuments =  documentConfig[appType][data.type_of_transaction];
    } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL') {

    } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FACILITY') {

    } else if(appType === 'APPLICATION_FOR_LANDING_CATCH_DECLARATION') {

    } else if(appType === 'APPLICATION_FOR_GENERAL_ADMINISTRATION') {

    } else if(appType === 'APPLICATION_FOR_FISHER_WORKER_ID') {
        allDocuments =  documentConfig[appType]
    }
    
    return allDocuments || [];
}