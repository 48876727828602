import React, { useContext, useEffect, useRef, useState } from 'react';
import SimpleNavigatorAnother from '../SimpleNavigatorAnother';

import { useDispatch, useSelector } from 'react-redux';
import { APP_SUBMISSION_CONFIG } from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
import { ROUTES, PARISH_LIST, ALL_DROPDOWNS } from "../../config/index";
import { getData, postData } from "../../services/service-call";
import {SET_CURRENT_TRANSACTION, saveCurrentTransaction} from '../../redux/actions/current-transaction';

import { useReactToPrint } from 'react-to-print';
import { FLISContext } from '../../context/FLISContext';
import ApplicationForLicence from '../../containers/Licence/ApplicationForLicence';
import ApplicationForPermit from '../../containers/PermitLicence/ApplicationForPermit';
import ApplicationForAuthorization from '../../containers/AuthorizationLicence/ApplicationForAuthorization';
import ApplicationForQuotaFishing from '../../containers/QuotaFishing/ApplicationForQuotaFishing';
import ApplicationForFishingVessel from '../../containers/FishingVesselLicence/ApplicationForFishingVessel';
import ApplicationForDeclaration from '../../containers/Declaration/ApplicationForDeclaration';
import ApplicationForAquaFishingVessel from '../../containers/AquacultureFishingVessel/ApplicationForAquaFishingVessel';
import ApplicationForAquaculture from '../../containers/AquacultureFisher/ApplicationForAquaFisher';
import ApplicationForAquaFacility from '../../containers/AquacultureFacility/ApplicationForAquaFacility';
import ApplicationForLandingLogs from '../../containers/LandingLogs/ApplicationForLandingLogs';
import ApplicationForGeneralAdministration from '../../containers/GeneralAdministration/ApplicationForGeneralAdministration';
import ApplicationForFisherWorkerId from '../../containers/FisherWorkerID/ApplicationForFisherWorkerId';
import SupportingDocumentsPreview from '../SupportingDocumentsPreview';
import PreviewPaymentDetails from './PreviewPaymentDetails';
import AlertDialogSlide from '../Modal/Modal';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CONCH_QUOTA, SPINY_LOBSTER_QUOTA, SEA_CUCUMBER_QUOTA } from '../../containers/QuotaFishing/QuotaFishingDataSource';

function FormPDFRender(props) {


    const {selectedMap, setSelectedMap, clearSelectedMap} = useContext(FLISContext);

    let data = localStorage.getItem('data');
    try{
        data = JSON.parse(data);
    }catch(e) {

    }
    const dispatch = useDispatch();

    const [appType, setAppType] = useState(data?.app_type);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const toShowDocument = queryParams.get('documentDetails') === 'true';
    const toShowPayment = queryParams.get('paymentDetails') === 'true';


    const generalFLISMetaData =  {
        img: 'application_top_header.png',
        fullImage: './images/application_top_header.png',
        footerText: ''
      };

    const appMetaData = {
      APPLICATION_FOR_DECLARATION: {
        footerText: "NFA APPLICATION FORMS – CLOSE SEASON SINGLE SPECIES | Version 1.0 November 2021",
        img: 'application_for_closed_seasons.png'
      },
      APPLICATION_QUOTA_FISHING: {
        footerText: (data) => {
            if(data.category === CONCH_QUOTA) return 'NFA APPLICATION FORMS – QUOTA AND FISHING RIGHTS - CONCH | Version 1.0 November 2021';
            else if(data.category === SPINY_LOBSTER_QUOTA) return 'NFA APPLICATION FORMS – Industrial Commercial Spiny Lobster Licence | Version 1.0 November 2021 ';
            else return '';
        },
        img: (data) => {
            if(data.category === CONCH_QUOTA) return './images/applications_for_quota_conch.png';
            else if(data.category === SPINY_LOBSTER_QUOTA) return './images/application_for_quata_spiny_lobster.png';
            else return generalFLISMetaData.fullImage;
        }
      },
      APPLICATION_FOR_AUTHORIZATION: {
        footerText: 'NFA APPLICATION FORMS – RESEARCH EDUCATIONAL AND SURVEY OPERATIONS | Version 1.0 November 2021',
        img: 'application_for_authorizations.png'
      },
      APPLICATION_FOR_PERMIT: {
        footerText: "NFA APPLICATION FORMS – REGISTRATION, LICENCES & PERMITS | Version 1.0 November 2021",
        img: 'application_for_licence.png'
      },
      APPLICATION_FOR_LICENCE: {
        footerText: "NFA APPLICATION FORMS – REGISTRATION, LICENCES & PERMITS | Version 1.0 November 2021",
        img: 'application_for_licence.png'
      },
      APPLICATION_FOR_FISHING_VESSEL: {
        footerText: "NFA APPLICATION FORMS Fishing Vessel Licence | Version 1.0 November 2021",
        img: 'application_for_fishing_vessel.png'
      },
      APPLICATION_FOR_AQUACULTURE_FISHER: generalFLISMetaData,
      APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: generalFLISMetaData,
      APPLICATION_FOR_AQUACULTURE_FACILITY: {
        footerText: 'NFA APPLICATION FORMS Commercial Aquaculture Facility | Version 2 | July 2023',
        img: 'application_for_aquaculture_facility.png'
      },
      APPLICATION_FOR_LANDING_CATCH_DECLARATION: generalFLISMetaData,
      APPLICATION_FOR_GENERAL_ADMINISTRATION: generalFLISMetaData,
      APPLICATION_FOR_FISHER_WORKER_ID: {
        footerText: "NFA APPLICATION FORMS – REGISTRATION, LICENCES & PERMITS | Version 1.0 November 2021",
        img: 'application_for_licence.png'
      },
      NOTICE_OF_VIOLATION: generalFLISMetaData
    };

    useEffect(() => {
        if(!data) {
            data = {};
            data.first_name_individual =  'Rishab Jain'
            data.app_type = 'APPLICATION_FOR_AQUACULTURE_FISHER';
            data.category = SEA_CUCUMBER_QUOTA;
            data.temp_trans_id = 'asfasf';
        }

        // setSelectedMap(data);
        
        const ultimateData = {
            ...data,
            temp_trans_id: data.temp_trans_id,
            app_type: data.app_type,
            payment_details: data.payment_details
          };

          clearSelectedMap(ultimateData);

          dispatch({
            type: SET_CURRENT_TRANSACTION,
            temp_trans_id: data.temp_trans_id,
            appType: data.app_type,
            transaction: {},
          });

          setAppType(data.app_type);


    }, []);


   

    let form;

    let properties = {
        isPreview: true,
        isFormPDF: true
    };
   
    if(selectedMap.app_type) {
        if (appType === 'APPLICATION_FOR_LICENCE'){
            form = <ApplicationForLicence appType={appType} {...properties}></ApplicationForLicence>;
        } else if(appType === 'APPLICATION_FOR_PERMIT') {
            form = <ApplicationForPermit {...properties}></ApplicationForPermit>;
        } else if(appType === 'APPLICATION_FOR_AUTHORIZATION') {
            form = <ApplicationForAuthorization {...properties}></ApplicationForAuthorization>;
        } else if(appType === 'APPLICATION_QUOTA_FISHING') {
            form = <ApplicationForQuotaFishing {...properties}></ApplicationForQuotaFishing>;
        } else if(appType === 'APPLICATION_FOR_DECLARATION') {
            form = <ApplicationForDeclaration {...properties}></ApplicationForDeclaration>;
        } else if(appType === 'APPLICATION_FOR_FISHING_VESSEL') {
            form = <ApplicationForFishingVessel {...properties}></ApplicationForFishingVessel>;
        } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHER') {
            form = <ApplicationForAquaculture {...properties}></ApplicationForAquaculture>;
        } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL') {
            form = <ApplicationForAquaFishingVessel {...properties}></ApplicationForAquaFishingVessel>;
        } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FACILITY') {
            form = <ApplicationForAquaFacility {...properties}></ApplicationForAquaFacility>;
        } else if(appType === 'APPLICATION_FOR_LANDING_CATCH_DECLARATION') {
            form = <ApplicationForLandingLogs {...properties}></ApplicationForLandingLogs>;
        } else if(appType === 'APPLICATION_FOR_GENERAL_ADMINISTRATION') {
            form = <ApplicationForGeneralAdministration {...properties}></ApplicationForGeneralAdministration>;
        } else if(appType === 'APPLICATION_FOR_FISHER_WORKER_ID') {
            form = <ApplicationForFisherWorkerId {...properties}></ApplicationForFisherWorkerId>;
        }
    
    }

    return (
      <div>
        <div>
          <div className="shadow-sm p-3 m-3 bg-light rounded">
            <div style={{ paddingTop: "2px" }}>

               <div>
              { appMetaData[appType]?.img && !toShowDocument &&
                 <img alt='Header IrieFINS' className='center-it' src={
                    typeof appMetaData[appType]?.img === 'string' ? 
                    `./images/${appMetaData[appType]?.img}` : appMetaData[appType]?.img(selectedMap)
                    }></img>
              }
               </div>

               <br></br>
              <div>{form}</div>
             {
                toShowDocument &&  <SupportingDocumentsPreview />
             }

              {/* add payment details here only so it gets included in print form */}
              {
                toShowPayment && <PreviewPaymentDetails />
              }


             {
              !toShowDocument &&  <div className='right-align'>
              {typeof appMetaData?.[appType]?.footerText === 'string' ? appMetaData?.[appType]?.footerText : appMetaData?.[appType]?.footerText(selectedMap)} 
            </div>
             }
            </div>
          </div>
        </div>
      </div>
    );
}

export default FormPDFRender;