export const ROUTES = {
    documentUpload : '/documents/upload',
    removeDocument: '/documents/remove-document/',
    getGuidelines: '/constants/guidelines?application_type=',
    getAllParish: '/constants/parish',
    getSpeciesSeasons: '/constants/species-seasons',
    updateSpeciesSeasons: '/constants/species-seasons',
    getUploadedDocuments: '/documents/uploaded-documents/',
    getAllDocuments: '/documents/all-documents/',
    getAllDropdowns: '/constants/all-dropdowns/',
    getTransactions: '/transactions',
    getTransaction : '/transactions/',
    registerUser: '/users/register',
    loginUser: '/users/login',
    resetPassword: '/users/password/reset',
    submitNewPassword: '/users/password/submit',
    createPasswordRequest: '/users/create-password-request',
    getZipForAllDocuments: '/documents/all-documents/',
    getTransactionTranscript: '/documents/transaction-transcipt/',
    saveConsumerComplaints: '/applications/consumer-complaint/save',
    saveReqForAdvice : '/applications/request-advice/save',
    verifyToken : '/users/verify-google-token-id',
    getProfileInfo: '/users/profile',
    launchGenericApplication: '/applications/generic-application',
    getDocumentDetailsForGenericApplication: '/documents/generic-application',
    getFormDetailsForGenericApplication: '/applications/generic-application',
    launchFlis: '/applications/launchFlis',
    saveApplication: '/applications/save-application',
    getAllUsers: '/users/',
    users: '/users',
    addUserToEntity: '/users/add/entity/',
    deleteUserFromEntity: '/users',
    updatePictureIdentification: '/entity/picture-identification/',
    revertGeneralCorrection: '/bpm/revert-general-correction/',
    getFisherRenewalData: '/bpm/renewals/fisher/',
    getVesselRenewalData: '/bpm/renewals/vessel/',
    getPermitRenewalData: '/bpm/renewals/permit/',
    getTransactionAudit: '/transactions/audit/',
    downloadDocumentByID : '/documents',
    updateEntityInformation: '/entity',
    getFisherInfoForRegistration: '/bpm/fisher',
    getAllEntitiesAssociated: '/users/entities',
    switchApplicant: '/users/switch',
    getAllIssuedCertificates: '/bpm/issued-certificates',
    downloadLicenceCertificate: '/bpm/case/documents',
    initiatePayment: '/payment/init',
    getPaymentFailureReason: '/payment/failure',
    searchTransactionsForAdmin: '/transactions/all',
    searchEntities: '/entity/search',
    searchUsersForEntity: '/entity/users',
    getALLSupportingOfficers: '/users/supporting-officers',
    searchUsers: '/users',
    replaceSupportingOfficerInEntity: '/entity/replace-supporting-officer',
    exportExcelReport: '/reports/export-excel',
    searchLicencesForAdmin: '/bpm/all-issued-certificates',
    validateTransactionNumber: '/entity/validate-transaction-number',
}

export const COLUMN_DEFINITIONS = {
    viewMerchants : [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Parish', field: 'parish' },
        { headerName: 'Email', field: 'email_address' },
        { headerName: 'Phone Number', field: 'phone_number' },
        { headerName: 'Street Address', field: 'street_address' },
        {
            headerName: 'Select',  cellRenderer: 'ActionCellRenderer', field: 'select_merchant_respondent', cellRendererParams: {
                type: 'select_merchant_respondent',
            },
        }
    ],

    viewMerchantsCAC: [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Name', field: 'name'},
        { headerName: 'Merchant Type', field: 'merchant_type'},
        { headerName: 'Website', field: 'Website'},
        {
            headerName: 'Select', cellRenderer: 'ActionCellRenderer', field: 'select_merchant_respondent', cellRendererParams: {
                type: 'select_merchant_respondent',
            },
        }
    ],

    viewBranchCAC: [
        { headerName: 'Name', field: 'name'},
        {headerName: 'City', field: 'city'},
        {headerName: 'Parish', field: 'parish'},
        {headerName: 'Telephone Number', field: 'contact_telephone_number'},
        {headerName: 'Email', field: 'contact_email'},
        {headerName: 'Contact First Name', field: 'contact_first_name'},
        {headerName: 'Contact Last Name', field: 'contact_last_name'},
        { headerName: 'State/Province/Region', field:'state_province_region'}
    ],
    viewTransactions: () => {
        return [
            {
                headerName: 'Transaction ID',
                field: 'transaction_number',
                width:240,
                
                cellRenderer: 'ActionCellRenderer',
                cellRendererParams: {
                    type: 'transaction_id',
                },
            },
            {
                headerName : localStorage.getItem('route') === 'ftc' ? 'Respondent Name' : "Merchant Name",
                valueGetter: (params) => {        
                    return params.data.respondent_details?.[0]?.name;
                },
                width:250
            },
            {
                headerName: localStorage.getItem('route') === 'ftc' ? 'Informant Name' : 'Complainant Name', 
                valueGetter : (params) => {
                    return params.data.informant_details?.[0]?.name
                },
                width:250
            },
            // {
            //     headerName : 'Application Type',
            //     field: 'app_type',
            //     width:250
            // },
            {
                headerName : 'Date Submitted',
                valueGetter: (params) => {
                    if(!params.data.submitted_on){
                        return "";
                    }
                    // return moment(params.data.submitted_on).format('MM/DD/YYYY')
                    return new Date(params.data.submitted_on).toDateString()
                },
                width:200
            },
            // {
            //     headerName : 'Application Status',
            //     field: 'status',
            //     width:260
            // },
            {
                headerName: 'Copy of Docs Submitted',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'zip',
                },
                width:140
            },
            /*{
                headerName: 'Transaction Transactipts',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'transcript',
                },
            },*/
            {
                headerName: 'Resume Application',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'continue_application',
                },
                width:200
            },
            {
                headerName: 'Status',
                cellRenderer: 'ActionCellRenderer',
                field: 'trans_id',
                cellRendererParams: {
                    type: 'track',
                },
                width:200
            },
        ]
    }
}


let allParish;
let allDropDowns;
(async () => {

    // allParish = getData({url: ROUTES.getAllParish});
    allParish = [];
    // allDropDowns = getData({url: ROUTES.getAllDropdowns});
    allDropDowns = [];
    
})();

export const PARISH_LIST = allParish;
export const ALL_DROPDOWNS = allDropDowns;  