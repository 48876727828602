import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa';

function FooterContainer(props) {
  
  return (
    <div className="footer-container row">
      <div className="col-sm-12">
        <div className="mt-2 ">
          Follow our social feeds to be the first to know when something happens
        </div>

        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem'
          }}>
            <div className="social-icons" style={{
              display: 'flex',
              gap: '1rem',
            }}>
              <FaFacebookF
                size={30}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.facebook.com/nfajamaica876', '_blank')}
              />
              <FaInstagram
                size={30}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.instagram.com/nfajamaica', '_blank')}
              />
              <FaYoutube
                size={30}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.youtube.com/@thenfajamaica', '_blank')}
              />
              <FaTwitter
                size={30}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://x.com/thenfajamaica', '_blank')}
              />
            </div>

            <a 
              href="/privacy-policy" 
              style={{ 
                color: 'inherit', 
                textDecoration: 'underline', 
                cursor: 'pointer' 
              }}
            >
              Privacy Policy
            </a>

            <button onClick={() => {
                window.open("https://iriefins.tawk.help/", "_blank");
            }} type="button" className="footer-feedback-button">
              Got feedback? Tell us what you think.
            </button>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="footer-text "></div>
      </div>
    </div>
  );
}

export default FooterContainer;
