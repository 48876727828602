import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getData, postData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import {
  FormHookInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import {
  getNameOfApplicant,
    getSimpleDateOnlyFormat,
  reportHeader,
} from "../../utils/utils";
import { alertService } from "../../_services";
import { desc } from "../../config/application-submission-config";
import AlertDialogSlide from "../../components/Modal/Modal";
import ReactJson from "react-json-view";
import { cloneDeep } from "lodash";
import { FLISContext } from '../../context/FLISContext';

import {
  searchEntitiesUIColumns,
} from "./AdminDataSource";
import { emailPattern } from "../../components/DataSource";
import { emailNotValid } from "../../utils/messages";
import { signInIcon } from "../../../assets/icons/icons_svg";

const parentStyle = { margin: "10px" };
const SupportingOfficerDashboard = () => {
  const history = useHistory();
  const linkRef = useRef();
  const [downloadHref, setDownloadHref] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const { getUserandEntityInfo } = useContext(FLISContext);

  const [allEntities, setAllEntities] = useState([]);

  const [supportingOfficers, setSupportingOfficers] = useState([]);

  // Separate form instance for search fields
  const {
    register: searchRegister,
    handleSubmit: handleSearchSubmit,
    formState: { errors: searchErrors },
    setValue: searchSetValue,
    getValues: searchGetValues,
    trigger: searchTrigger,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  // Separate form instance for login
  const {
    register: loginRegister,
    handleSubmit: handleLoginSubmit,
    formState: { errors: loginErrors },
  } = useForm({
    mode: "all",
  });

  const handleLogin = async (data) => {
    try {
      const result = await postData({
        url: ROUTES.loginUser,
        query: {loginWithoutEntity: 'yes'},
        body: {
          user_name: data.email,
          password: data.password,
        },
      });

      if (result.statusCode === 200) {
        const response = result.data;
        localStorage.setItem('token', response.token);
        setShowLoginModal(false);
        getUserandEntityInfo();
        if (pendingAction) {
          pendingAction();
          setPendingAction(null);
        }
      }
    } catch (error) {
      alertService.error('Login failed. Please try again.');
    }
  };

  const handleClaim = async (entity, userId) => {
    if (!checkAuthAndProceed(() => handleClaim(entity, userId))) return;

    const result = await putData({
      url: `${ROUTES.replaceSupportingOfficerInEntity}/${entity.id}/${userId || '0'}`,
    });

    if (result.statusCode === 200) {
      alertService.success(result.data.msg);
      submit();
    } else {
      alertService.warn(result.data.msg);
    }
  };

  const handleViewAccounts = () => {
    if (!checkAuthAndProceed(() => handleViewAccounts())) return;
    history.push('/admin/supporting-officer?showAssociatedEntities=yes');
  };

  const submit = async () => {
    const isValid = await searchTrigger();
    if (!isValid) {
      return;
    }

    const data = searchGetValues();

    const result = await getData({
      url: `${ROUTES.searchEntities}`,
      query: data,
    });

    if (result.statusCode === 200) {
      alertService.success(result.data.msg);
      setAllEntities(result.data.entities);
    } else {
      alertService.warn(result.data.msg);
      setAllEntities([]);
    }
  };

  const checkAuthAndProceed = (action) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setShowLoginModal(true);
      setPendingAction(() => action);
      return false;
    }
    return true;
  };

  const TransactionList = () => {
    return (
      <div style={parentStyle}>
        <a ref={linkRef} href={downloadHref} download={downloadFileName}></a>

      

        <ResponsiveTable
          isBordered={true}
          headers={searchEntitiesUIColumns}
          className="table-responsive table-striped"
          headerClass="blue-gradient-bg"
        >
          <tbody style={{ fontWeight: 600 }}>
            {allEntities?.map((entity, index) => {
              return (
                <tr key={entity.id}>
                  <td>{index + 1}</td>
                  <td>{entity.nfa_registration_number}</td>
                  {/* <td>{entity?.users?.supporting_officer_name}</td> */}
                  <td>{getNameOfApplicant(entity)}</td>
                  <td>{entity?.applicant_email_address || entity?.applicant_cell_phone}</td>
                  <td>{entity?.supporting_officer_name}</td>
                  <td>
                    {entity?.users?.entity_user
                      ?.is_replaced_supporting_officer === "YES"
                      ? getSimpleDateOnlyFormat(
                          entity?.users?.entity_user.updated_at
                        )
                      : null}
                  </td>

                  <td>
                    <button
                      className="d-flex mb-2 justify-content-center flex-column btn btn-sm btn-warning"
                      onClick={() => handleClaim(entity, entity?.users?.id ? entity?.users?.id : '0')}
                      title="Click here to add to your list of Fishers"
                    >
                      Claim
                    </button>

                    <button
                      className="d-flex justify-content-center flex-column btn btn-sm btn-primary"
                      onClick={handleViewAccounts}
                      title="Click here to view all Fishers"
                    >
                      View Accounts
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </div>
    );
  };

  useEffect(() => {

    // get all supporting officers
    const fetchSupportingOfficers = async () => {
        const result = await getData({
            url: `${ROUTES.getALLSupportingOfficers}`,
            headers: {
                token: process.env.REACT_APP_API_TOKEN,
            }
        });
    
        if (result.statusCode === 200) {
            const users = result.data.users;
            setSupportingOfficers(users.map((user) => {
                return {
                    value: user.id,
                    label: user.email,
                }
            }));
        } else {
            alertService.error(result.data.msg);
        }
    }
    fetchSupportingOfficers();

  }, []);

  return (
    <div className="simple-border-2">
      <div className="simple-border" style={{ margin: "10px" }}>
        {reportHeader("Supporting Officer Dashboard")}

        <div style={parentStyle}>
          {/* Search Fields */}
          <div className="d-flex-wrap flex-row justify-content-start">
            <FormHookInput
              register={searchRegister}
              error={searchErrors?.nfa_registration_number?.message}
              label="NFA Reg #"
              regFieldName="nfa_registration_number"
              isRequired={false}
            />

            <FormHookSelect
              parentClass=" individual-field  max-500 flex-full-height"
              fieldClassName="form-control "
              label="Select Supporting Officer (Optional)"
              register={searchRegister}
              regFieldName={`supporting_officer_id`}
              options={supportingOfficers}
              isRequired={false}
              isKeyValue={true}
              defaultSelectValueText="All Supporting Officers"
              defaultSelectValue=""
            />
          </div>

          {/* CTAS */}
          <div className="m-2 d-flex-wrap flex-row justify-content-end">
            <button
              className="btn btn-primary"
              onClick={handleSearchSubmit(submit)}
            >
              Submit to see all Accounts
            </button>
          </div>
        </div>
      </div>
      <TransactionList></TransactionList>
      <AlertDialogSlide
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
        hideCrossIcon={true}
        title="Login Required"
        content={
          <div className="p-4">
            <form onSubmit={handleLoginSubmit(handleLogin)}>
              <FormHookInput
                register={loginRegister}
                error={loginErrors?.email?.message}
                label="Email"
                regFieldName="email"
                isRequired={true}
                validationRules={{
                  required: "Email is required",
                  pattern: {
                    value: emailPattern,
                    message: emailNotValid,
                  },
                }}
              />
              <FormHookInput
                register={loginRegister}
                error={loginErrors?.password?.message}
                label="Password"
                regFieldName="password"
                type="password"
                isRequired={true}
                validationRules={{
                  required: "Password is required",
                }}
              />
              <div className="mt-3">
                <button type="submit"   style={{ margin: "0px 10px" }} className="btn btn-custom-success mb-4">
                Sign In {signInIcon}
                </button>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default SupportingOfficerDashboard;