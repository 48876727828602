import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar/navbar';

import { BrowserRouter, Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import HomeContainer from './Home';


import LicenceApplicationSubmission from "./Licence/LicenceApplicationSubmission";
import TransactionOverview from "../components/TransactionOverview";

import {Dashboard} from "../components/Dashboard";
import {HowToApply} from "../components/HowToApply";
import Community from '../components/Community';

import ApplicationDetails from "../components/ApplicationDetails";
import {FAQ} from "../components/FAQ";
import {Support} from "../components/Support";

import LoginContainer from './LoginMultipleEnhancements2';
import { ForgotPassword } from './ForgotPassword';
import OldLoginContainer from './Login';
import Registration from './Registration';
import Profile from './Profile';
import FormSubmission from '../components/FormSubmission';
import ResetPassword from './ResetPassword';
import TypeOfApplications from './TypeOfApplications';
import SecondLandingPage from '../components/SecondLandingPage';
import ResponsiveNavbar from '../components/Navbar/ResponsiveNavbar';
import Map from './GoogleMap';
import { GoogleMapClickable } from './GoogleMapClickable';

import { FLISContextComponent } from '../context/FLISContext';
import PermitDetails from '../components/PermitDetails';
import PermitApplicationSubmission from './PermitLicence/PermitApplicationSubmission';
import AuthorizationDetails from '../components/AuthorizationDetails';
import AuthorizationApplicationSubmission from './AuthorizationLicence/AuthorizationApplicationSubmission';
import QuotaFishingDetails from '../components/QuotaFishingDetails';
import QuotaFishingApplicationSubmission from './QuotaFishing/QuotaFishingApplicationSubmission';
import DeclarationApplicationSubmission from './Declaration/DeclarationApplicationSubmission';
import DeclarationDetails from '../components/DeclarationDetails';
import FishingVesselApplicationDetails from '../components/FishingVesselApplicationDetails';
import FishingVesselLicenceApplicationSubmission from './FishingVesselLicence/FishingVesselLicenceApplicationSubmission';
import AquacultureDetails from '../components/AquacultureFisherDetails';
import LandingLogDetails from '../components/LandingLogDetails';
import AquacultureFishingVesselDetails from '../components/AquacultureFishingVesselDetails';
import AquacultureFacilityDetails from '../components/AquacultureFacilityDetails';
import AquacultureFisherApplicationSubmission from './AquacultureFisher/AquaFisherApplicationSubmission';
import AquaFishingVesselApplicationSubmission from './AquacultureFishingVessel/AquaFishingVesselApplicationSubmission';
import AquaFacilityApplicationSubmission from './AquacultureFacility/AquaFacilityApplicationSubmission';
import LandingLogApplicationSubmission from './LandingLogs/LandingLogApplicationSubmission';
import GeneralAdministrationDetails from '../components/GeneralAdministrationDetails';
import GeneralAdministrationApplicationSubmission from './GeneralAdministration/GeneralAdministrationApplicationSubmission';
import FisherWorkerIdDetails from '../components/FisherWorkerIdDetails';
import FisherWorkerIdApplicationSubmission from './FisherWorkerID/FisherWorkerIdApplicationSubmission';
import EntityPortal from './EntityPortal';
import ViolationNotice from './ViolationNoticeContainer';
import ManageSpeciesSeasons from './AdminModule/ManageClosedSeason';
import FormPDFRender from '../components/FormSteps/FormPDFRender';
import SearchTransactions from './AdminModule/SearchTransactions';
import AdminHome from './AdminModule/AdminHome';
import SearchLicences from './AdminModule/SearchLicences';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReactGA from 'react-ga4';
import { TransactionStatus } from '../components/TransactionStatus';
import EntityDashboard from './AdminModule/EntityDashboard';
import AgencyDirectory from './AdminModule/G2GDashboard';
import SupportingOfficerDashboard from './AdminModule/SupportingOfficerDashboard';
import UsersDashboard from './AdminModule/Users';

import {PrivacyPolicy} from './PrivacyPolicy';

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}



function LandingContainer(props) {

  const location = useLocation();

  useEffect(() => {
    // console.log("URL changed:", location.pathname);
    if(location?.pathname?.indexOf('/login') === -1 && location?.pathname?.indexOf('/logout') === -1 && location?.pathname?.indexOf('/reset-password') === -1 && location?.pathname?.indexOf('/reset-password') === -1) {
      localStorage.setItem('url_to_navigate', `${location.pathname}${location.search}`);
    }
  }, [location]);

  usePageViews();

    return (
      <FLISContextComponent>
        {/* <BrowserRouter> */}
        <ResponsiveNavbar></ResponsiveNavbar>

        <Switch>
          <Route exact path="/support" component={Support} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/clickable-map" component={GoogleMapClickable} />
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/reset-password" component={ForgotPassword} />
          <Route exact path="/legacy-login" component={OldLoginContainer} />
          <Route exact path="/register" component={Registration} />
          <Route exact path="/reset-password/:uid" component={ResetPassword} />
          <Route exact path="/form-render-for-pdf" component={FormPDFRender} />
          <Route exact path="/profile" component={Profile} />
          {/* <Route exact path="/dashboard" component={Dashboard} /> */}
          <Route exact path="/transaction-status" component={TransactionStatus} />


          <Route exact path="/entity-portal" component={EntityPortal} />
          <Route exact path="/home" component={HomeContainer} />

          {/* <Route exact path="/home">
            <Redirect to="/application-types"></Redirect>
          </Route> */}

          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/how-to-apply" component={HowToApply}></Route>
          <Route
            exact
            path="/view-transaction"
            component={TransactionOverview}
          ></Route>
          <Route exact path="/community" component={Community}></Route>
          <Route
            exact
            path="/application-details"
            component={ApplicationDetails}
          />

          <Route
            exact
            path="/fisher-worker-details"
            component={FisherWorkerIdDetails}
          />

          <Route
            exact
            path="/fishing-vessel-details"
            component={FishingVesselApplicationDetails}
          />

          <Route exact path="/permit-details">
            <PermitDetails></PermitDetails>
          </Route>

          <Route exact path="/authorization-details">
            <AuthorizationDetails></AuthorizationDetails>
          </Route>

          <Route exact path="/quota-fishing-details">
            <QuotaFishingDetails></QuotaFishingDetails>
          </Route>

          <Route exact path="/declaration-details">
            <DeclarationDetails></DeclarationDetails>
          </Route>

          <Route exact path="/aquaculture-fisher-details">
            <AquacultureDetails></AquacultureDetails>
          </Route>

          <Route exact path="/aquaculture-fishing-vessel-details">
            <AquacultureFishingVesselDetails></AquacultureFishingVesselDetails>
          </Route>

          <Route exact path="/aquaculture-facility-details">
            <AquacultureFacilityDetails></AquacultureFacilityDetails>
          </Route>

          <Route exact path="/landing-logs-details">
            <LandingLogDetails></LandingLogDetails>
          </Route>

          <Route exact path="/general-administration-details">
            <GeneralAdministrationDetails></GeneralAdministrationDetails>
          </Route>

          <Route  path="/application/licence">
            <LicenceApplicationSubmission></LicenceApplicationSubmission>
          </Route>

          <Route  path="/application/fisher-worker-id">
            <FisherWorkerIdApplicationSubmission></FisherWorkerIdApplicationSubmission>
          </Route>

          <Route  path="/application/fishing-vessel">
            <FishingVesselLicenceApplicationSubmission></FishingVesselLicenceApplicationSubmission>
          </Route>

          <Route  path="/application/permit">
            <PermitApplicationSubmission></PermitApplicationSubmission>
          </Route>

          <Route  path="/application/authorization">
            <AuthorizationApplicationSubmission></AuthorizationApplicationSubmission>
          </Route>

          <Route  path="/application/quota-fishing">
            <QuotaFishingApplicationSubmission></QuotaFishingApplicationSubmission>
          </Route>

          <Route  path="/application/declaration">
            <DeclarationApplicationSubmission></DeclarationApplicationSubmission>
          </Route>

          <Route  path="/application/aquaculture-fisher">
            <AquacultureFisherApplicationSubmission></AquacultureFisherApplicationSubmission>
          </Route>

          <Route  path="/application/aquaculture-fishing-vessel">
            <AquaFishingVesselApplicationSubmission></AquaFishingVesselApplicationSubmission>
          </Route>

          <Route  path="/application/aquaculture-facility">
            <AquaFacilityApplicationSubmission></AquaFacilityApplicationSubmission>
          </Route>

          <Route  path="/application/landing-logs">
            <LandingLogApplicationSubmission></LandingLogApplicationSubmission>
          </Route>

          <Route path="/application/general-administration">
            <GeneralAdministrationApplicationSubmission></GeneralAdministrationApplicationSubmission>
          </Route>

          <Route  path="/application/submit-violation-notice">
            <ViolationNotice/>
          </Route>


          <Route  path="/form-submission/:appType">
            <FormSubmission></FormSubmission>
          </Route>

          <Route path="/application-types">
            <TypeOfApplications></TypeOfApplications>
          </Route>

          <Route path="/privacy-policy">
            <PrivacyPolicy></PrivacyPolicy>
          </Route>

          <Route exact path="/admin">
              <AdminHome/>
          </Route>

          <Route exact path="/admin/species-seasons">
              <ManageSpeciesSeasons/>
          </Route>

          <Route exact path="/admin/transactions">
              <SearchTransactions/>
          </Route>

          <Route exact path="/admin/licences">
              <SearchLicences/>
          </Route>

          <Route exact path="/admin/supporting-officer">
              <SupportingOfficerDashboard/>
          </Route>

          <Route exact path="/admin/users">
              <UsersDashboard/>
          </Route>

          <Route exact path="/admin/g2g">
              <AgencyDirectory/>
          </Route>

          <Route path="*">
            <Redirect to="/application-types"></Redirect>
          </Route>
        </Switch>
        {/* </BrowserRouter> */}
      </FLISContextComponent>
    );
}

export default LandingContainer;