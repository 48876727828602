export const PrivacyPolicy = () => {
    return (
        <div style={{ 
            maxWidth: '1200px', 
            margin: '0 auto', 
            padding: '2rem 1.5rem',
            lineHeight: '1.6'
        }}>
            <h3 style={{ 
                fontSize: '3rem', 
                marginBottom: '2rem',
                color: '#1a73e8',
                textAlign: 'center'
            }}>Privacy Notice</h3>

            <p style={{ marginBottom: '1.5rem' }}>
                The National Fisheries Authority Jamaica (NFA) with an address of 2C Newport East, Kingston 11, is responsible for the efficient and effective management and sustainable development of fisheries, aquaculture and other related activities in Jamaica.  The following explains how the NFA, as a data controller, collects, processes and protects the data you provide.
            </p>

            <h4 style={{ 
                fontSize: '1.5rem', 
                marginTop: '2rem',
                marginBottom: '1rem',
                color: '#444'
            }}>Legal Basis & How We Use Your Data</h4>

            <p style={{ marginBottom: '1.5rem' }}>
                As a public authority, the NFA will collect, in the discharge of its duties, personal data and sensitive personal data (e.g. photographs).  The personal and sensitive personal data you provide will be used by the NFA in the execution of duties conferred on it by the Fisheries Act, 2018.  
            </p>

            <p style={{ marginBottom: '1rem' }}>
                These include:
            </p>

            <ul style={{ 
                marginBottom: '1.5rem',
                paddingLeft: '2rem'
            }}>
                <li style={{ marginBottom: '0.5rem' }}>Granting and renewing individual fishing licences</li>
                <li style={{ marginBottom: '0.5rem' }}>Granting and renewing vessel licences</li>
                <li style={{ marginBottom: '0.5rem' }}>Grating and renewing fish farmer licences</li>
                <li style={{ marginBottom: '0.5rem' }}>Granting and renewing facilities licences</li>
                <li style={{ marginBottom: '0.5rem' }}>Grant and renewing fish worker IDs</li>
                <li style={{ marginBottom: '0.5rem' }}>Granting permits</li>
                <li style={{ marginBottom: '0.5rem' }}>Granting quotas and fishing rights</li>
                <li style={{ marginBottom: '0.5rem' }}>Granting authorizations</li>
                <li style={{ marginBottom: '0.5rem' }}>Landing catch declaration</li>
                <li style={{ marginBottom: '0.5rem' }}>Processing verification letters</li>
                <li>Other relevant services</li>
            </ul>

            <p style={{ marginBottom: '1.5rem' }}>
                The personal data you provide may be used internally for quality assurance purposes which would involve contacting you to ask about the level of customer service you received. It may also be shared with other government ministries, departments or agencies including the Jamaica Defence Force and the Jamaica Constabulary Force or any other local entity where there is a legal basis or where a legal obligation exists.  Your personal data may also be shared with our parent Ministry as needed in the execution of our functions as a Public Authority. 
            </p>

            <h4 style={{ 
                fontSize: '1.5rem', 
                marginTop: '2rem',
                marginBottom: '1rem',
                color: '#444'
            }}>Data Subject Rights</h4>

            <p style={{ marginBottom: '1.5rem' }}>
                Under the Data Protection Act 2020, data subjects have several rights.  These include the right to access their personal data, to consent to processing, to consent to direct marketing, to prevent processing of their personal data, to object to automated decision making and to rectify inaccuracies.
                <br/><br/>
                You may exercise these rights by submitting a written request to the authority.
            </p>

            <h4 style={{ 
                fontSize: '1.5rem', 
                marginTop: '2rem',
                marginBottom: '1rem',
                color: '#444'
            }}>How Long Do We Keep Your Data</h4>

            <p style={{ marginBottom: '1.5rem' }}>
                The NFA retains your personal data for specific periods depending on its status and purpose. Active records, which are required for ongoing operations or compliance, are retained for approximately seven (7) years. Once the information is no longer actively in use, it is classified as non-active and archived for a maximum of twenty (20) years to fulfill legal, regulatory, or operational requirements, after which it is securely destroyed.
            </p>

            <h4 style={{ 
                fontSize: '1.5rem', 
                marginTop: '2rem',
                marginBottom: '1rem',
                color: '#444'
            }}>How We Secure Your Data</h4>

            <p style={{ marginBottom: '1.5rem' }}>
                The NFA is committed to maintaining the confidentiality, integrity and availability of your data.  To this end, we have implemented rigorous technical and organizational controls to ensure that your data is protected from unauthorized access, destruction, or alteration.  Our internal data protection and information security policies guide how your personal data is accessed, stored, processed and managed
            </p>

            <p style={{ marginBottom: '1.5rem' }}>
                Should you have any questions about this privacy notice or any of our data protection policies, please contact our Data Protection Officer: 
            </p>

            <div style={{
                background: '#f5f5f5',
                padding: '1.5rem',
                borderRadius: '4px',
                marginTop: '1.5rem'
            }}>
                <p style={{ 
                    margin: '0',
                    whiteSpace: 'pre-line'
                }}>
                    {'Data Protection Officer\n' +
                    'National Fisheries Authority\n' +
                    '2C Newport East, Kingston 11\n\n' +
                    'Email: dataprotectionofficer@nfa.gov.jm\n' +
                    'Phone: 876-948-9014, 876-948-6933'}
                </p>
            </div>
        </div>
    );
};