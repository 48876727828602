import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

export const apiHeaders = () => {

    let header = {
        'content-type': 'application/json'
    }

    if(localStorage.getItem('token')){
        header.Authorization = localStorage.getItem('token');
    }
    return header;
}

export async function getData({ url, headers = {}, query = {} }) {
    url = process.env.REACT_APP_API_BASE_URL + url;

    return new Promise((resolve, reject) => {
        trackPromise(axios.get(url, {
            headers: {
                ...headers, ...apiHeaders()
            },
            params: query
        })
            .then(res => {
                if(res.data.statusCode === 401){
                    localStorage.setItem('token', '');
                    window.location = "/login";
                }
                resolve(res.data);
            }).catch(error => {
                if (error.message === 'Request failed with status code 401') {
                    localStorage.setItem('token', '');
                    window.location = "/login";
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    reject(error);
                }

            }));
    })
}


export async function deleteData({ url, headers = {}, query }) {
    url = process.env.REACT_APP_API_BASE_URL + url;

    return new Promise((resolve, reject) => {
        trackPromise(axios.delete(url, {
            headers: {
                ...headers, ...apiHeaders()
            }
        })
            .then(res => {
                if(res.data.statusCode === 401){
                    localStorage.setItem('token', '');
                    window.location = "/login";
                }
                resolve(res.data);
            }).catch(error => {
                if (error.message === 'Request failed with status code 401') {
                    localStorage.setItem('token', '');
                    window.location = "/login";
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    reject(error);
                }

            }));
    })
}

async function syncPostCallLater() {
  const registration = await navigator.serviceWorker.ready;
  try {
    await registration.sync.register("post-submission-data");
  } catch {
    console.log("Background Sync could not be registered!");
  }
}

export async function postData({ url, headers = {}, query = {}, body, timeout = 3 * 60000 }) {
    url = process.env.REACT_APP_API_BASE_URL + url;


    // if(url.indexOf('/applications/save-application') !== -1 && !navigator.onLine) {
    //     syncPostCallLater();
    //     return;
    // }


    return new Promise((resolve, reject) => {
        trackPromise(axios.post(url, body, {
            headers: {
                ...headers, ...apiHeaders()
            },
            params: query,
            timeout: timeout
        }).then(res => {
            resolve(res.data);
        }).catch(error => {
            if (error.message === 'Request failed with status code 401') {
                localStorage.setItem('token', '');
                window.location = "/login";
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                resolve(error);
            }

        }))
    })
}



export async function putData({ url, headers = {}, query, body }) {
    url = process.env.REACT_APP_API_BASE_URL + url;

    return new Promise((resolve, reject) => {
        trackPromise(axios.put(url, body, {
            headers: {
                ...headers, ...apiHeaders()
            }
        }).then(res => {
            resolve(res.data);
        }).catch(error => {
            if (error.message === 'Request failed with status code 401') {
                localStorage.setItem('token', '');
                window.location = "/login";
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                reject(error);
            }

        }))
    })
}

export async function getDataWithoutLoader({ url, headers = {}, query }) {
  url = process.env.REACT_APP_API_BASE_URL + url;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          ...headers,
          ...apiHeaders(),
        },
      })
      .then((res) => {
        if (res.data.statusCode === 401) {
          localStorage.setItem("token", "");
          window.location = "/login";
        }
        resolve(res.data);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.setItem("token", "");
          window.location = "/login";
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          reject(error);
        }
      });
  });
}

export async function postDataWithoutLoader({ url, headers = {}, query, body }) {
    url = process.env.REACT_APP_API_BASE_URL + url;

    return new Promise((resolve, reject) => {
        axios.post(url, body, {
            headers: {
                ...headers, ...apiHeaders()
            }
        }).then(res => {
            resolve(res.data);
        }).catch(error => {
            if (error.message === 'Request failed with status code 401') {
                localStorage.setItem('token', '');
                window.location = "/login";
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                reject(error);
            }

        })
    })
}
