import React, { useState, useEffect } from "react";
import { getData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { alertService } from "../../_services";
import { searchEntitiesUIColumns, searchUsersUIColumns } from "./AdminDataSource";
import { reportHeader } from "../../utils/utils";
import AlertDialogSlide from "../../components/Modal/Modal";
import ResetPassword from "../ResetPassword";

const parentStyle = { margin: "10px" };
const UsersDashboard = () => {
  const [allUsers, setAllUsers] = useState([]);
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });
  const [passwordResetDialog, showPasswordResetDialog] = useState(false);



  const { errors } = formState;

  const submit = async () => {

   
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    const data = getValues();

    const result = await getData({
      url: `${ROUTES.searchUsers}`,
      query: data,
    });

    if (result.statusCode === 200) {
      alertService.success(result.data.msg);
      setAllUsers(result.data.users);
    } else {
      alertService.warn(result.data.msg);
      setAllUsers([]);
    }
  };

  useEffect(() => {
    // Fetch initial data if needed
  }, []);

  return (
    <div className="simple-border-2">
      <AlertDialogSlide
        fullWidth={true}
        open={passwordResetDialog}
        title={""}
        handleClose={() => {
          showPasswordResetDialog(false);
        }}
        handleCloseSecondary={() => {
          showPasswordResetDialog(false);
        }}
        primaryActionName={"Close"}
        content={<>
            <ResetPassword></ResetPassword>
        </>}
      ></AlertDialogSlide>

      <div className="simple-border" style={{ margin: "10px" }}>
        {reportHeader("User Dashboard")}

        <div style={parentStyle}>
          {/* Search Fields */}
          <div className="d-flex-wrap flex-row justify-content-start">
            <FormHookInput
              register={register}
              error={errors?.email_address?.message}
              label="Email Address"
              regFieldName="Email Address"
              isRequired={false}
            />
            <FormHookInput
              register={register}
              error={errors?.cell_phone_user?.message}
              label="Cell Phone #"
              regFieldName="cell_phone_user"
              isRequired={false}
            />
          </div>

          {/* CTAS */}
          <div className="m-2 d-flex-wrap flex-row justify-content-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={async () => {
                submit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div style={parentStyle}>
        <ResponsiveTable
          isBordered={true}
          headers={searchUsersUIColumns}
          className="table-responsive table-striped"
          headerClass="blue-gradient-bg"
        >
          <tbody style={{ fontWeight: 600 }}>
            {allUsers?.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.email}</td>
                <td>{user.cell_phone_user}</td>
                {/*  here in this cell 3 dots icon on click of that we can show the actions like edit, delete, view etc.
                 */}

                <td>
                  <button
                    className="d-flex justify-content-center flex-column btn btn-warning"
                    onClick={async () => {

                        // send request to create password request endpoint
                        
                        const result = await putData({url: `${ROUTES.createPasswordRequest}/${user.id}`});

                        console.log(result);

                        if(result.statusCode === 200){

                            // open a new tab to reset password
                            window.open(`reset-password/${result.data.id}`, '_blank');
                        }else{
                            alertService.warn(result.data.msg);
                        }

                        // show password reset dialog

                        // showPasswordResetDialog(true);
                    }}
                  >
                    Reset Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
      </div>
    </div>
  );
};

export default UsersDashboard;
