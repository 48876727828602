import { getReadableDateFromMonthAndDate } from "../../utils/utils";

export const INDUSTRIAL_VESSEL_CREW_MEMBER = "Crew Member (Industrial Vessel)";
export const MORANT_PEDRO = "Morant & Pedro";
export const TEMPORARY_PERMIT = "Temporary Permit";
export const TOURNAMENT_HOSTING = "Tournament Hosting";
export const OTHER = "Other";


export const SPORTS_TOURNAMENT = "Sports Tournament";
export const RECREATIONAL_FISHING = "Recreational Fishing";
export const typeOfPermit = [
    INDUSTRIAL_VESSEL_CREW_MEMBER,
    MORANT_PEDRO,
    TEMPORARY_PERMIT,
    TOURNAMENT_HOSTING
];

// applicant role in Fisheries  already present with same values in Data Source.js file
export const applicantRoleInFisheries = [
  "Vessel Owner",
  "Captain",
  "Diver",
  "Crew",
  "Fishery Worker",
  "Processor",
  "Supplier",
  "Researcher",
  "Fish Perveyor / Vendor",
  "Other",
  // "Aqua / Mariculture Facility Owner",
  // "Aqua/ Mariculture Facility Operator Manager",
  // "Aqua/ Mariculture Facility Worker",
  // "Ornamental Culture Operator"
];

export const applicantRoleAsCrew = {
    // [INDUSTRIAL_VESSEL_CREW_MEMBER]: [
    //     "Captain", "First Mate", "Diver", "Cook", "Crew Member", "Engineer", "Other"
    // ],
    // Zahra confirmed these values for Industrial Vessel to be same as Fisher Licence
    [INDUSTRIAL_VESSEL_CREW_MEMBER]: applicantRoleInFisheries,
    [MORANT_PEDRO] : ["Captain", "Diver", "Crew Member", "Vessel owner", "Fisheries Worker"],
    [TEMPORARY_PERMIT]: [""],
    [TOURNAMENT_HOSTING]: [""]
}

export const NEW_PERMIT = "New Permit";
export const typeOfTransactions = {
  [INDUSTRIAL_VESSEL_CREW_MEMBER]: [
    NEW_PERMIT,
    "Renewal",
    "Amendment",
    // "Substitute (Amendment)",
  ],
  [MORANT_PEDRO]: [
    NEW_PERMIT,
    "Renewal",
    "Amendment",
    // "Substitute (Amendment)",
  ],
  [TEMPORARY_PERMIT]: [NEW_PERMIT, "Renewal"],
  [TOURNAMENT_HOSTING]: [NEW_PERMIT, "Renewal"],
};

export const domicileType = [
    "Local", "Foreign"
]

export const typeOfSpecies = {
  // "Ornamentals" -- removed
  [INDUSTRIAL_VESSEL_CREW_MEMBER]: [
    "Spiny Lobster",
    // "Conch",
    "Conch (Other conch)",
    "Conch (Queen conch)",
    "Sea Cucumber",
    "Reef Fish",
    // "Reef Fishery",
    // "Octopus/Squid",
    "Octopus / Squid",
    "Coastal Pelagics",
    // "Crab",
    "Crabs",
    // "Sea Moss / Weed",
    "Irish Moss / Sea Weeds",
    "Inland FinFish",
    "Shrimp Freshwater",
    "Shrimp Marine",
    // "Deep / Slope Snapper",
    "Deep Slope / Snapper",
    // "Offshore Tunas / Pelagics",
    "Bait",
    "Glass eels",
    // "Other",
    "Other Aquatic Plants"
  ],

  // "Ornamentals" -- removed
  [MORANT_PEDRO]: [
    "Spiny Lobster",
    // "Conch",
    "Conch (Other conch)",
    "Conch (Queen conch)",
    "Sea Cucumber",
    "Reef Fish",
    // "Reef Fishery",
    // "Octopus/Squid",
    "Octopus / Squid",
    "Coastal Pelagics",
    // "Crab",
    "Crabs",
    // "Sea Moss / Weed",
    "Irish Moss / Sea Weeds",
    // "Shrimp",
    "Shrimp Freshwater",
    "Shrimp Marine",
    // "Deep / Slope Snapper",
    "Deep Slope / Snapper",
    "Offshore Pelagics",
    // "Offshore Tunas / Pelagics",
    // "Other",
    "Other Aquatic Plants",
  ],
  // "Lobster" removed after Elena said for temp permit - Recreational it not required
  // [TEMPORARY_PERMIT]: ["Fin Fish", "Reef Fish"],  // fin fish removed as there is no option in BPM discuss with Elena
  // [TEMPORARY_PERMIT]: ["Reef Fishery"], 
  // [TEMPORARY_PERMIT]: ["Reef Fish"], 
  [TEMPORARY_PERMIT]: [
    "Spiny Lobster",
    // "Conch",
    "Conch (Other conch)",
    "Conch (Queen conch)",
    "Sea Cucumber",
    "Reef Fish",
    // "Reef Fishery",
    // "Octopus/Squid",
    "Octopus / Squid",
    "Coastal Pelagics",
    // "Crab",
    "Crabs",
    // "Sea Moss / Weed",
    "Irish Moss / Sea Weeds",
    "Inland FinFish",
    "Shrimp Freshwater",
    "Shrimp Marine",
    // "Deep / Slope Snapper",
    "Deep Slope / Snapper",
    // "Offshore Tunas / Pelagics",
    "Bait",
    "Glass eels",
    // "Other",
    "Other Aquatic Plants"
  ]
};

export const permitRequiredFor = {
  [MORANT_PEDRO]: ["Fishing from the Cays", "Access to Field Station", "Shop Owners", "General Access"]
}

export const gearTypeToBeLicenced = {
    [INDUSTRIAL_VESSEL_CREW_MEMBER]:  ["Traps", "Nets", "Lines", "Scuba",  "Free Lung", "Other"],
    [MORANT_PEDRO]: ["Traps", "Nets", "Lines", "Scuba",  "Free Lung", "Other"],
    [TEMPORARY_PERMIT]: ["Traps", "Nets", "Lines", "Scuba", "Free Lung", "Other"],

}

export const purposeOfPermit = {
    [TEMPORARY_PERMIT]: [
       SPORTS_TOURNAMENT ,RECREATIONAL_FISHING , OTHER
    ]
}

export const fishingArea = [
    { "id": 1, "location": "North Shelf", "lat": 17.8000, "lng": -77.2000 },
    { "id": 2, "location": "South Shelf", "lat": 17.9000, "lng": -77.3000 },
    { "id": 3, "location": "New Bank", "lat": 0, "lng": 0 },
    { "id": 4, "location": "Blossom Bank", "lat": 0, "lng": 0 },
    { "id": 5, "location": "Walton Bank", "lat": 0, "lng": 0 },
    { "id": 6, "location": "Mackerel Bank", "lat": 0, "lng": 0 },
    { "id": 7, "location": "California Bank", "lat": 0, "lng": 0 },
    { "id": 8, "location": "Dingle Bank", "lat": 0, "lng": 0 },
    { "id": 9, "location": "Norseman Bank", "lat": 0, "lng": 0 },
    { "id": 10, "location": "Portland Bight", "lat": 18.2000, "lng": -76.3000 },
    { "id": 11, "location": "Albatross Bank", "lat": 0, "lng": 0 },
    { "id": 12, "location": "Henry Holmes", "lat": 0, "lng": 0 },
    { "id": 13, "location": "Grappler Bank", "lat": 0, "lng": 0 },
    { "id": 18, "location": "Bowditch Bank", "lat": 0 , "lng": 0},
    { "id": 14, "location": "Formigas Bank", "lat": 0, "lng": 0 },
    { "id": 15, "location": "Pedro Bank", "lat": 0, "lng": 0 },
    { "id": 16, "location": "Morant Bank", "lat": 0, "lng": 0 },
    // { "id": 17, "location": "Economic Zone EEZ", "lat": 0, "lng": 0 }
    // { "id": 17, "location": "Special Economic Zone", "lat": 0, "lng": 0 } 
    { "id": 17, "location": "Exclusive Economic Zone", "lat": 0, "lng": 0 } 
];


export const fishingAreaPermitLocations = fishingArea.map(area => area.location);
// not used any where
export const typeOfLicence = [
  "Artisanal Fishing",
  "Industrial Fishing",
  "Recreational Fishing",
  "Sports / Tournament",
  "Purveyor / Vendor",
  "Research or Exploratory",
  // removed for permit
  // "Aquaculture",
  // "Mariculture",
  // "Ornamental Culture"
];



  export const permitTypeCodeMapping =  {
    [INDUSTRIAL_VESSEL_CREW_MEMBER]: "TTL",
    [MORANT_PEDRO]: "CAY",
    [TOURNAMENT_HOSTING]: "TR",
    [TEMPORARY_PERMIT]: {
      [OTHER]: "SP",
      [RECREATIONAL_FISHING]: "SP",
      [SPORTS_TOURNAMENT]: "TP"
    }
  };


  export const permitTypesDateAllowed = {
    [MORANT_PEDRO]: {
      start_date: '12-01',
      end_date: '01-31',
      description: `${MORANT_PEDRO} is only open from ${getReadableDateFromMonthAndDate('12-01')} to ${getReadableDateFromMonthAndDate('01-31')}`
    }
  }