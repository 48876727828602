import React, { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import HorizontalLine from "../components/HorLine";
import {
  FormHookCheckbox,
  FormHookInput,
  FormHookRadioInput,
  FormHookSelect,
  FormHookTextArea,
} from "../components/FormHook/ReactFormHookFields";
import {
  Break,
  educationTypes,
  emailPattern,
  formatPhoneNumber,
  formatTRN,
  getMaxDateForAtleastYear,
  getSVGImage,
  individualNoDocList,
  individualYesDocList,
  parishList,
  telephonePattern,
  trnPattern,
} from "../components/DataSource";
import { SignaturePad } from "../components/SignatureCanvas";
import { emailNotValid, telephoneNotValid, trnNotValid } from "../utils/messages";
import { alertService } from "../_services";
import { getData, postData } from "../services/service-call";
import { ROUTES } from "../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BoxChoiceContainer from "../components/BoxChoiceContainer";
import { companyIcon, individualIcon } from "../../assets/icons/icons_svg";
import AlertDialogSlide from "../components/Modal/Modal";
import { countries } from "./AuthorizationLicence/AuthorizationDataSource";
import { Link } from "react-router-dom";
import { FormHookFileUploadHandler } from "../components/FormHook/FormHookDocument";
import { concatValidStrings, dataComplianceTerms, getNameOfApplicant, passwordAndReenterShouldBeSame } from "../utils/utils";


const PreForm = ({ register, errors, selectedMap }) => {
  return (
    <div>
      <div className="d-flex-wrap flex-column justify-content-start">
        {selectedMap.have_trn === "Yes" && (
          <FormHookInput
            register={register}
            error={errors?.taxation_registration_number?.message}
            label="TRN"
            regFieldName="taxation_registration_number"
            placeholder="TRN"
            isRequired={false}
            validations={{
              pattern: {
                value: trnPattern,
                message: trnNotValid,
              },
            }}
            onChange={formatTRN}
          />
        )}

        {selectedMap.have_nfa === "Yes" && (
          <FormHookInput
            register={register}
            error={errors?.nfa_registration_number?.message}
            label="NFA Registration #"
            regFieldName="nfa_registration_number"
            placeholder="NFA Registration #"
            isRequired={false}
            validations={{
              maxLength: { value: 100, message: "Max length is 100" },
              minLength: { value: 3, message: "Min length is 3" },
            }}
          />
        )}

        <FormHookInput
          register={register}
          error={errors?.nids_number?.message}
          label="NIDS #"
          regFieldName="nids_number"
          placeholder="NIDS #"
          isDisabled={true}
          isRequired={false}
        />
      </div>
    </div>
  );
};

// const ComplianceForm = ({registrationData, onSuccess, onFailed, register, errors, watch, setValue, getValues}) => {

//   return (
//     <div style={{ fontSize: "14px" }}>
//         <>
//           <div>
//             I, {getNameOfApplicant(registrationData)}, have read and understood
//             this Customer Compliance Statement: Data Protection ACT – (DPA) 2020
//             provided by the National Fisheries Authority (NFA) of Jamaica. I
//             acknowledge and agree to the following:
//           </div>

//           <br></br>

//           <ul>
//             {dataComplianceTerms?.map((term) => {
//               return (
//                 <li style={{ padding: "2px" }} key={term}>
//                   {term}
//                 </li>
//               );
//             })}
//           </ul>

//           <br></br>
//           <div>
//             By signing below, I confirm my understanding and acceptance of the
//             terms and conditions of this statement.
//           </div>


//           <div className="error-message">
//             {!watch("applicant_signature") &&
//               !watch("signature_draw_path") && (
//                 <span>Atleast one field is required from below</span>
//               )}
//           </div>

//           <div className="d-flex-wrap flex-row justify-content-start">
//           <FormHookFileUploadHandler
//               error={
//                 errors?.applicant_signature
//                   ?.message
//               }
//               register={register}
//               watch={watch}
//               label="Upload Applicant's Signature"
//               accept=".jpg, .jpeg, .png"
//               regFieldName="applicant_signature"
//               fieldClassName="field-block-control"
//               isPreviewable={true}
//               isRequired={false}
//               removeFile={(id) => setValue(id, null)}
//               setValue={setValue}
//             ></FormHookFileUploadHandler>


//           <SignaturePad
//             isDisabled={false}
//             currentValue={null}
//             setSignatureValue={(id, base64) => setValue(id, base64)}
//             id="signature_draw_path"
//             label="Draw Your Signature Below"
//           />
//             </div>

//           <div>
//             <br></br>
//             <ul>
//               <li>
//                 Click{" "}
//                 <Link
//                   to="/forms/NFA Data Protection Policy.docx"
//                   target="_blank"
//                   download
//                 >
//                   {" "}
//                   here{" "}
//                 </Link>
//                 for a link to our full Privacy Policy
//               </li>
//               <li>
//                 Submit any data protection concerns to dataprotection@nfa.gov.jm
//               </li>
//             </ul>
//           </div>

//           <div className="d-flex justify-content-end">
//             <button
//               className="btn btn-primary min-200"
//               onClick={async () => {

//                 const applicant_signature = getValues().applicant_signature;
//                 const signature_draw_path = getValues().signature_draw_path;

//                 let base64;
//                 if(applicant_signature) {
//                   base64 = `data:${applicant_signature.contentType};base64,${applicant_signature.content}`;
//                 }else {
//                   base64 = signature_draw_path
//                 }
//                 if (!signature_draw_path && !applicant_signature) {
//                   alertService.error(
//                     "Please put your signature before submitting",
//                     {
//                       autoClose: true,
//                       keepAfterRouteChange: true,
//                     }
//                   );
//                   return;
//                 }

//                 const result = await postData({
//                   url: ROUTES.registerUser,
//                   body: {
//                     ...registrationData,
//                     signature: base64
//                   },
//                 });
//                 if (result.statusCode === 200) {
//                   alertService.success("Registered Successfully", {
//                     autoClose: true,
//                     keepAfterRouteChange: true,
//                   });
//                   onSuccess();
//                 } else {
//                   alertService.error(result.data.msg, {
//                     autoClose: true,
//                     keepAfterRouteChange: true,
//                   });
//                 }
//               }}
//               type="submit"
//             >
//               I Consent
//             </button>

//             <button
//               style={{marginLeft: '10px'}}
//               onClick={() => {
//                 onFailed();
//               }}
//               className="btn btn-warning min-200">
//               I Don't Consent
//             </button>
//           </div>
//         </>
//     </div>
//   );
// }


const ComplianceForm = ({registrationData, onSuccess, onFailed, register, errors, watch, setValue, getValues}) => {

  return (
    <div style={{ fontSize: "14px", paddingLeft: '40px' , paddingRight: '40px' }}>
        <>
         


            <div>
                By submitting this form, you confirm that you have read and understood the contents of our <a target="_blank" href="https://iriefins.nfa.gov.jm/privacy-policy">Privacy Notice</a> and agree to the terms outlined therein.
            </div>
            
            <br/>
            
            <div>You further agree that:</div>

            <ol>
                <li>
                    The information you provide is accurate and complete to the best of your knowledge.
                </li>

                <li>
                    You understand your rights under the <b>Data Protection Act (2020)</b>, including the right to access, correct, or request deletion of your personal data.
                </li>
            </ol>

            <br/>
            
            <div>
                For additional details about how we collect, use, and protect your data, please refer to our <a target="_blank" href="https://iriefins.nfa.gov.jm/privacy-policy">Privacy Notice.</a> If you have any questions or concerns, please contact our Data Protection Officer at National Fisheries Authority - 2C Newport East, Kingston 11 or by email: dataprotectionofficer@nfa.gov.jm
            </div>
             
        

          <br></br>
          <div>
            By signing below, I confirm my understanding and acceptance of the
            terms and conditions of this statement.
          </div>


          <div className="error-message">
            {!watch("applicant_signature") &&
              !watch("signature_draw_path") && (
                <span>Atleast one field is required from below</span>
              )}
          </div>

          <div className="d-flex-wrap flex-row justify-content-start">
          <FormHookFileUploadHandler
              error={
                errors?.applicant_signature
                  ?.message
              }
              register={register}
              watch={watch}
              label="Upload Applicant's Signature"
              accept=".jpg, .jpeg, .png"
              regFieldName="applicant_signature"
              fieldClassName="field-block-control"
              isPreviewable={true}
              isRequired={false}
              removeFile={(id) => setValue(id, null)}
              setValue={setValue}
            ></FormHookFileUploadHandler>


          <SignaturePad
            isDisabled={false}
            currentValue={null}
            setSignatureValue={(id, base64) => setValue(id, base64)}
            id="signature_draw_path"
            label="Draw Your Signature Below"
          />
            </div>

        

<br></br>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary min-200"
              onClick={async () => {

                const applicant_signature = getValues().applicant_signature;
                const signature_draw_path = getValues().signature_draw_path;

                let base64;
                if(applicant_signature) {
                  base64 = `data:${applicant_signature.contentType};base64,${applicant_signature.content}`;
                }else {
                  base64 = signature_draw_path
                }
                if (!signature_draw_path && !applicant_signature) {
                  alertService.error(
                    "Please put your signature before submitting",
                    {
                      autoClose: true,
                      keepAfterRouteChange: true,
                    }
                  );
                  return;
                }

                const result = await postData({
                  url: ROUTES.registerUser,
                  body: {
                    ...registrationData,
                    signature: base64
                  },
                });
                if (result.statusCode === 200) {
                  alertService.success("Registered Successfully", {
                    autoClose: true,
                    keepAfterRouteChange: true,
                  });
                  onSuccess();
                } else {
                  alertService.error(result.data.msg, {
                    autoClose: true,
                    keepAfterRouteChange: true,
                  });
                }
              }}
              type="submit"
            >
              I Consent
            </button>

            <button
              style={{marginLeft: '10px'}}
              onClick={() => {
                onFailed();
              }}
              className="btn btn-warning min-200">
              I Don't Consent
            </button>
          </div>
        </>
    </div>
  );
}

const Registration = () => {

  const reviewSectionStyles = {position: 'fixed', bottom: '0px' , marginTop: '20px', maxWidth: '1000px', width: '100%'};
  const formStylingBeforeReviewSection = { maxWidth: "1000px", margin: "auto", maxHeight: 'calc(100vh - 300px)', overflow: 'scroll' };
  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      contact_details: [{ id: Math.random() }],
      supporting_officer_role: "Not Applicable"
        // "nids_number": "",
        // "taxation_registration_number": "asf",
        // "nfa_registration_number": "asf",
        // "domicile": "Foreign",
        // "category_of_applicant": "Commercial",
        // "first_name_individual": "8",
        // "middle_name_individual": "989",
        // "last_name_individual": "89",
        // "alias_individual": "89",
        // "country_of_origin": "Afghanistan",
        // "gender": "Male",
        // "date_of_birth_individual": "2005-12-07",
        // "cell_phone": "90",
        // "parish": "Hanover",
        // "address": "90",
        // "entity_picture_identification": {
        //     "picture_identification": "Yes",
        //     "type_of_picture_identification": "Drivers Licence",
        //     "picture_identification_number": "90",
        //     "picture_expiry_date": "1995-11-11",
        //     "picture_identification_type_for_no": "Birth Certificate + Picture Identification",
        //     "birth_certificate_file": "",
        //     "photo_identification_file": ""
        // },
        // "applying_as": "Applicant",
        // "name_of_applicant": "asfaf",
        // "email": "fasf@ddd.com",
        // "cell_phone_user": "112-323-2232",
        // "password": "password",
        // "reenterpassword": "password",
        // "applicant_type": "Individual"
    },
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_details",
  });

  const { errors } = formState;

  const history = useHistory();

  const SUBMIT_TEXT = 'Review Privacy Policy';

  const typeOfApplicant = watch("type_of_applicant");
  const haveNFARegNumber = watch("type_of_fisher");
  const havePictureIdentification = watch("entity_picture_identification.picture_identification");

  const educationType = watch("education") || [];

  const pictureIdentificationForNo = watch(
    "entity_picture_identification.picture_identification_type_for_no"
  );

  const [isDateOfBirthDisabled, setIsDateOfBirthDisabled] = useState(false);

  const onSubmit = async (values) => {
   
    if (values.password !== values.reenterpassword) {
      alertService.error(passwordAndReenterShouldBeSame);
      return;
    }
    values.applicant_type = selectionCategory.applicant_type;
    console.log(values);
    setRegistrationData(values);
    setShowCompliance(true);
    // const result = await postData({ url: ROUTES.registerUser, body: values });
    // if (result.statusCode === 200) {
    //   alertService.success("Registered Successfully", {
    //     autoClose: true,
    //     keepAfterRouteChange: true,
    //   });
    //   history.push("/login");
    // } else {
    //   alertService.error(result.data.msg, {
    //     autoClose: true,
    //     keepAfterRouteChange: true,
    //   });
    // }
  };

  const [selectionCategory, setSelectionCategory] = useState(1);

  const [selectedMap, setSelectedMap] = useState({});

  const [dialog, showDialog] = useState(false);

  const [registrationData, setRegistrationData] = useState({});

  const [showCompliance, setShowCompliance] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const setSelectionCriteria = (key, value) => {
    setSelectedMap((map) => {
      return {
        ...map,
        [key]: value,
      };
    });
  };

  const soType = watch('supporting_officer_role');

  const login_as = watch('login_as');
  const applicantEmail = watch('applicant_email_address');
  const applicantCellPhone = watch('applicant_cell_phone');

  const supportingOfficerEmail = watch('supporting_officer_email');


  useEffect(() => {

    if(login_as === 'Applicant'){
      setValue('email', getValues().applicant_email_address);
      setValue('cell_phone_user', getValues().applicant_cell_phone);
    } else if (login_as === 'Supporting Officer'){
      setValue('email', getValues().supporting_officer_email);
      setValue('cell_phone_user', '');
    }

    if(soType  === "Not Applicable") {
      setValue('login_as', 'Applicant');
    } else {
      setValue('login_as', 'Supporting Officer');
    }
  }, [login_as, applicantCellPhone, applicantEmail, supportingOfficerEmail, soType]);

  const SupportingOfficer = (
    <div className="shadow bg-white rounded registration-border mb-3 pb-3">
      <div className="sub-form-header-registration">Supporting Officer <b style={{color: 'blue'}}>(For official use only)</b></div>
      <HorizontalLine></HorizontalLine>

      <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
        <FormHookSelect
          register={register}
          error={errors?.supporting_officer_role?.message}
          label="Supporting Officer"
          regFieldName="supporting_officer_role"
          options={[
            "Not Applicable",
            "Clerical Officer",
            "Records Officer",
            "Cashier",
            "Field Officer",
            
          ]}
          fieldClassName="form-control form-control-sm"
        />

        <FormHookInput
          register={register}
          error={errors?.supporting_officer_name?.message}
          label="Supporting Officer Name"
          regFieldName="supporting_officer_name"
          placeholder="Supporting Officer Name"
          type="text"
          fieldClassName="form-control form-control-sm"
          isRequired={soType !== "Not Applicable" ? "This field is required": false}
        />

        <FormHookInput
          register={register}
          error={errors?.supporting_officer_email?.message}
          label="Supporting Officer Email"
          regFieldName="supporting_officer_email"
          placeholder="Supporting Officer Email"
          validations={{
            pattern: {
              value: emailPattern,
              message: emailNotValid,
            },
          }}
          fieldClassName="form-control form-control-sm"
          isRequired={soType !== "Not Applicable" ? "This field is required": false}
        />
      </div>
    </div>
  );

  const ApplicantDetails = (
    <div className="shadow bg-white rounded registration-border mb-3 pb-3">
      <div className="sub-form-header-registration">Applicant Details</div>
      <HorizontalLine></HorizontalLine>

      <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
        <FormHookSelect
          register={register}
          error={errors?.applying_as?.message}
          label="Applying As"
          regFieldName="applying_as"
          options={["Applicant", "Company on Behalf of Crew Member"]}
          fieldClassName="form-control form-control-sm"
        />

        <FormHookInput
          register={register}
          error={errors?.name_of_applicant?.message}
          label="Name"
          regFieldName="name_of_applicant"
          placeholder="Name"
          type="text"
          fieldClassName="form-control form-control-sm"
        />

        <FormHookInput
          register={register}
          error={errors?.applicant_cell_phone?.message}
          label="Applicant Cell Phone"
          regFieldName={`applicant_cell_phone`}
          placeholder="Applicant Cell Phone"
          type="text"
          validations={{
            pattern: {
              value: telephonePattern,
              message: telephoneNotValid,
            },
          }}
          onChange={formatPhoneNumber}
          fieldClassName="form-control form-control-sm"
          isRequired={
            !watch("applicant_email_address") ? "This field is required" : false
          }
          toAddOptionalText={false}
        />

        <FormHookInput
          register={register}
          error={errors?.applicant_email_address?.message}
          label="Applicant Email Adddress"
          regFieldName="applicant_email_address"
          placeholder="Applicant Email Adddress"
          validations={{
            pattern: {
              value: emailPattern,
              message: emailNotValid,
            },
          }}
          fieldClassName="form-control form-control-sm"
          isRequired={
            !watch("applicant_cell_phone") ? "This field is required" : false
          }
          toAddOptionalText={false}
        />
      </div>
    </div>
  );

  return (
    <div className="register-body">
      <AlertDialogSlide
        fullWidth={false}
        open={dialog}
        title={"Submit Details"}
        handleClose={async () => {
          const isValid = await trigger();
          if(!isValid) return;
          const { nfa_registration_number, taxation_registration_number } = getValues();

          let result = {};
          if (nfa_registration_number || taxation_registration_number) {
            result = await postData({
              url: `${ROUTES.getFisherInfoForRegistration}`,
              body: {nfa_registration_number, taxation_registration_number},
              timeout: 30 * 1000
            }).catch(err => {
              console.log(err);
              showDialog(false);
              return ;
            });
          }

          if(result?.statusCode >= 400) {
            alertService.error(result.data.msg, {
              autoClose: true,
              keepAfterRouteChange: false,
            });
            return;
          }

          if(result?.dateofBirth?.split("T")?.[0]) {
            setIsDateOfBirthDisabled(true);
          } else {
            setIsDateOfBirthDisabled(false);
          }

          showDialog(false);
          setValue("first_name_individual", result?.nameofApplicant);
          setValue("middle_name_individual", result?.nameofApplicantMiddle);
          setValue("last_name_individual", result?.nameofApplicantLast);
          setValue(
            "company_name",
            concatValidStrings(
              result?.nameofApplicant,
              result?.nameofApplicantMiddle,
              result?.nameofApplicantLast
            )
          );
          setValue("address", result?.addresse);
          setValue("parish", result?.parish);
          setValue(
            "date_of_birth_individual",
            result?.dateofBirth?.split("T")?.[0]
          );
          setValue("gender", result?.gender);
          setSelectionCategory((map) => {
            return {
              ...map,
              applicant_type: selectedMap.applicant_type,
              first_name_individual: result?.nameofApplicant ?? "",
              middle_name_individual: result?.nameofApplicantMiddle ?? "",
              last_name_individual: result?.nameofApplicantLast ?? "",
              company_name: concatValidStrings(
                result?.nameofApplicant,
                result?.nameofApplicantMiddle,
                result?.nameofApplicantLast
              ),
              address: result?.addresse ?? "",
              parish: result?.parish,
              date_of_birth_individual: result?.dateofBirth?.split("T")?.[0],
              gender: result?.gender,
              show: true,
            };
          });
          alertService.success("Fill Form", {
            autoClose: true,
            keepAfterRouteChange: false,
          });
        }}
        handleCloseSecondary={() => {
          showDialog(false);
        }}
        primaryActionName={"Proceed"}
        secondaryActionName={"Close"}
        content={
          <PreForm
            register={register}
            selectedMap={selectedMap}
            errors={errors}
          ></PreForm>
        }
      />

      <AlertDialogSlide
        fullWidth={true}
        open={showCompliance}
        title={
          "Customer Acknowledgement Statement of Privacy Notice Statement"
        }
        handleClose={() => {setShowCompliance(false)}}
        handleCloseSecondary={() => {
          setShowCompliance(false);
        }}
        style={{ width: '1300px', maxWidth: "100%", margin: "auto" }}
        content={
          <ComplianceForm
            registrationData={registrationData}
            onSuccess={() => {
              setShowCompliance(false);
              setShowSuccess(true);
            }}
            onFailed={() => {
              setShowCompliance(false);
            }}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            getValues={getValues}
          ></ComplianceForm>
        }
      />

      <AlertDialogSlide
        fullWidth={false}
        open={showSuccess}
        title={"Registration Successful"}
        handleClose={() => {
          history.push("/login");
        }}
        handleCloseSecondary={() => {
          history.push("/login");
        }}
        primaryActionStyle={{ backgroundColor: "#336699", color: "white" }}
        // style={{ width: "800px", maxWidth: "100%", margin: "auto" }}
        primaryActionName={"Login"}
        // secondaryActionName={""}
        content={
          <div>
            <div>
              Your Registration was Successful! - Please log in with your User
              Name and Password.
              <div>
                You will also receive an email notification informing you of
                your Registration Details.
              </div>
              <div>
                Enjoy your Journey with IreFins! and Have a Wonderful Day!
              </div>
            </div>
          </div>
        }
      />

      {selectionCategory === 1 && (
        <div className="d-flex-wrap justify-content-center flex-column align-items-center">
          <div className="head-text mb-2">New Registration - Portal</div>
          <table className="table table-bordered max-900">
            <tbody>
              <tr>
                <td>Select Type of Applicant</td>
                <td className="">
                  <div>
                    <button
                      onClick={() =>
                        setSelectionCriteria("applicant_type", "Individual")
                      }
                      className={`btn btn-bordered btn-100 mr-1 ${
                        selectedMap.applicant_type === "Individual"
                          ? "btn-primary"
                          : ""
                      }`}
                    >
                      Individual
                    </button>
                    <button
                      onClick={() =>
                        setSelectionCriteria("applicant_type", "Company")
                      }
                      className={`btn btn-bordered btn-100 ${
                        selectedMap.applicant_type === "Company"
                          ? "btn-primary"
                          : ""
                      }`}
                    >
                      Company
                    </button>
                  </div>
                </td>
              </tr>

              <tr>
                <td> Do you have a TRN Number</td>
                <td className="">
                  <div>
                    <button
                      onClick={() => setSelectionCriteria("have_trn", "Yes")}
                      className={`btn btn-bordered btn-100 mr-1 ${
                        selectedMap.have_trn === "Yes" ? "btn-success" : ""
                      }`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => setSelectionCriteria("have_trn", "No")}
                      className={`btn btn-bordered btn-100 ${
                        selectedMap.have_trn === "No" ? "btn-danger" : ""
                      }`}
                    >
                      No
                    </button>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Do you have a NFA Registration Number</td>
                <td className="width-300">
                  <div>
                    <button
                      onClick={() => setSelectionCriteria("have_nfa", "Yes")}
                      className={`btn btn-bordered btn-100 mr-1 ${
                        selectedMap.have_nfa === "Yes" ? "btn-success" : ""
                      }`}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => setSelectionCriteria("have_nfa", "No")}
                      className={`btn btn-bordered btn-100 ${
                        selectedMap.have_nfa === "No" ? "btn-danger" : ""
                      }`}
                    >
                      No
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex-wrap mb-3  max-900 full-width justify-content-end">
            <button
              className="btn btn-primary btn-100"
              type="submit"
              onClick={() => {
                if (
                  !(
                    selectedMap.have_trn &&
                    selectedMap.have_nfa &&
                    selectedMap.applicant_type
                  )
                ) {
                  alertService.warn("Select all choices first", {
                    autoClose: true,
                    keepAfterRouteChange: false,
                  });

                  return;
                }

                if (
                  selectedMap.have_trn === "Yes" ||
                  selectedMap.have_nfa === "Yes"
                ) {
                  showDialog(true);
                } else {
                  setSelectionCategory((map) => {
                    return {
                      ...map,
                      ...selectedMap,
                      show: true,
                    };
                  });
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {selectionCategory.applicant_type === "Individual" &&
        selectionCategory.show && (
          <fieldset>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={formStylingBeforeReviewSection}
            >
              <div className="shadow  bg-white rounded registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Registration Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap flex-column mb-2 ">
                  <div className="d-flex-wrap justify-content-between registration-margin">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Reg. #"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration #"
                      isRequired={false}
                      isDisabled={true}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.taxation_registration_number?.message}
                      label="TRN"
                      regFieldName="taxation_registration_number"
                      placeholder="TRN"
                      isRequired={false}
                      // isDisabled={true}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                      validations={{
                        pattern: {
                          value: trnPattern,
                          message: trnNotValid,
                        },
                      }}
                      onChange={formatTRN}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.domicile?.message}
                      label="Nationality"
                      regFieldName="domicile"
                      options={["Local", "Foreign"]}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.category_of_applicant?.message}
                      label="Category of Applicant"
                      regFieldName="category_of_applicant"
                      options={["Commercial", "Recreational", "Temporary"]}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />
                  </div>
                  <div className="d-flex-wrap gap-40-10 justify-content-between"></div>
                </div>
              </div>
              <div className="shadow rounded bg-white registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Individual Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap flex-row justify-content-between registration-margin">
                  <FormHookInput
                    register={register}
                    error={errors?.first_name_individual?.message}
                    label="First Name"
                    regFieldName="first_name_individual"
                    placeholder="First"
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.middle_name_individual?.message}
                    label="Middle Name"
                    regFieldName="middle_name_individual"
                    placeholder="Middle"
                    isRequired={false}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.last_name_individual?.message}
                    label="Last Name"
                    regFieldName="last_name_individual"
                    placeholder="Last"
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.alias_individual?.message}
                    label="Alias"
                    regFieldName="alias_individual"
                    placeholder="Alias"
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                    isRequired={false}
                  />

                  <FormHookSelect
                    label="Country of Origin"
                    register={register}
                    error={errors?.country_of_origin?.message}
                    regFieldName="country_of_origin"
                    options={countries}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookSelect
                    register={register}
                    error={errors?.gender?.message}
                    label="Gender"
                    regFieldName="gender"
                    options={["Male", "Female"]}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.date_of_birth_individual?.message}
                    label="Date of Birth"
                    regFieldName="date_of_birth_individual"
                    type="date"
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                    maxDate={getMaxDateForAtleastYear(16)}
                    isDisabled={isDateOfBirthDisabled}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.cell_phone?.message}
                    label="Work Cell Phone"
                    regFieldName={`cell_phone`}
                    placeholder="Cell Phone"
                    type="text"
                    validations={{
                      pattern: {
                        value: telephonePattern,
                        message: telephoneNotValid,
                      },
                    }}
                    onChange={formatPhoneNumber}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  {/* <FormHookInput
                    register={register}
                    error={errors?.work_home_phone?.message}
                    label="Work / Home Phone"
                    regFieldName={`work_home_phone`}
                    placeholder="Work / Home Phone"
                    type="text"
                    isRequired={false}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  /> */}

                  <FormHookSelect
                    label="Parish"
                    register={register}
                    error={errors?.parish?.message}
                    regFieldName="parish"
                    options={parishList}
                    fieldClassName="form-control form-control-sm"
                    parentClass={"individual-field-margin min-200 max-200"}
                  />

                  <FormHookTextArea
                    fieldClassName="form-control form-control-sm"
                    register={register}
                    error={errors?.address?.message}
                    label="Address"
                    regFieldName="address"
                    placeholder="Address"
                    // cols={61}
                    rows={1}
                    parentClass="individual-field-text-area full-width-flex"
                  />

                  <FormHookCheckbox
                    label="Education"
                    options={educationTypes}
                    register={register}
                    error={errors?.education?.message}
                    regFieldName={`education`}
                  />

                  {educationType?.includes("Other") && (
                    <FormHookInput
                      register={register}
                      error={errors?.education_other?.message}
                      label="Other (Education)"
                      regFieldName="education_other"
                      placeholder="Other Education"
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                      isRequired={
                        educationType?.includes("Other")
                          ? "This field is required"
                          : false
                      }
                    />
                  )}

                  {/* <FormHookFileUploadHandler
                    error={errors?.self_photo_file?.message}
                    register={register}
                    watch={watch}
                    label="Picture ID"
                    accept="*"
                    regFieldName="self_photo_file"
                    fieldClassName="field-block-control   max-250"
                    parentClass="individual-field max-250"
                    isPreviewable={false}
                    removeFile={(id) => setValue(id, null)}
                    setValue={setValue}
                    isRequired={false}
                  /> */}
                </div>
              </div>

              <div className="shadow rounded bg-white registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Picture Identification -
                  <span style={{ fontSize: "12px" }}>
                    If you do not have a Picture ID - please select either Birth
                    Certificate + Picture ID or Statutory Declaration
                  </span>
                </div>
                <HorizontalLine></HorizontalLine>
                <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
                  <FormHookSelect
                    label="Do You have a Picture Identification"
                    register={register}
                    error={
                      errors?.entity_picture_identification
                        ?.picture_identification?.message
                    }
                    regFieldName="entity_picture_identification.picture_identification"
                    options={["Yes", "No"]}
                    fieldClassName="form-control form-control-sm"
                  />

                  {havePictureIdentification === "Yes" && (
                    <FormHookSelect
                      label="Select Picture Identification"
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.type_of_picture_identification?.message
                      }
                      regFieldName="entity_picture_identification.type_of_picture_identification"
                      options={individualYesDocList}
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {havePictureIdentification === "Yes" && (
                    <FormHookInput
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.picture_identification_number?.message
                      }
                      label="Picture Identification Number"
                      regFieldName="entity_picture_identification.picture_identification_number"
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {havePictureIdentification === "Yes" && (
                    <FormHookInput
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.picture_expiry_date?.message
                      }
                      label="Expiry Date"
                      regFieldName="entity_picture_identification.picture_expiry_date"
                      type="date"
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {havePictureIdentification === "Yes" && (
                    <FormHookFileUploadHandler
                      error={
                        errors?.entity_picture_identification
                          ?.identification_document?.message
                      }
                      register={register}
                      watch={watch}
                      label="Upload Document"
                      accept="*"
                      regFieldName="entity_picture_identification.identification_document"
                      fieldClassName="field-block-control"
                      parentClass="individual-field flex-grow-1"
                      isPreviewable={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                      isRequired={
                        havePictureIdentification === "Yes"
                          ? "This field is required"
                          : false
                      }
                      toShowInstructionLink={true}
                    />
                  )}

                  {havePictureIdentification === "No" && (
                    <>
                      <FormHookSelect
                        label="Identification Doc to be Uploaded"
                        register={register}
                        error={
                          errors?.entity_picture_identification
                            ?.picture_identification_type_for_no?.message
                        }
                        fieldClassName="form-control form-control-sm"
                        regFieldName="entity_picture_identification.picture_identification_type_for_no"
                        options={[
                          "Birth Certificate + Picture Identification",
                          "Statutory Certificate",
                        ]}
                      />

                      <Break />

                      {pictureIdentificationForNo ===
                        "Birth Certificate + Picture Identification" && (
                        <>
                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.birth_certificate_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Birth Certificate"
                            accept="*"
                            regFieldName="entity_picture_identification.birth_certificate_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          ></FormHookFileUploadHandler>

                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.photo_identification_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Picture"
                            accept="*"
                            regFieldName="entity_picture_identification.photo_identification_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          ></FormHookFileUploadHandler>
                        </>
                      )}

                      {pictureIdentificationForNo ===
                        "Statutory Certificate" && (
                        <>
                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.statutory_certificate_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Statutory Certificate"
                            accept="*"
                            regFieldName="entity_picture_identification.statutory_certificate_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          />

                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                            class="alert alert-primary"
                            role="alert"
                          >
                            If No - Download and fill out the attached
                            <Link
                              to="/forms/Statutory_Declaration_of_Date_of_Birth.pdf"
                              target="_blank"
                              download
                            >
                              {" "}
                              Statutory Declaration of Date of Birth{" "}
                            </Link>
                            (Upload the completed form at the “Upload Statutory
                            Certificate")
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              {ApplicantDetails}
              {SupportingOfficer}

              <div className="shadow bg-white rounded registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Login Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
                  {/* <FormHookSelect
                    register={register}
                    error={errors?.applying_as?.message}
                    label="Applying As"
                    regFieldName="applying_as"
                    options={["Applicant", "Company on Behalf of Crew Member"]}
                    fieldClassName="form-control form-control-sm"
                  /> */}

                  {/* <FormHookInput
                    register={register}
                    error={errors?.name_of_applicant?.message}
                    label="Name"
                    regFieldName="name_of_applicant"
                    placeholder="Name"
                    type="text"
                    fieldClassName="form-control form-control-sm"
                  /> */}

                  <FormHookRadioInput
                    fieldClassName="d-flex-wrap flex-column"
                    options={["Applicant", "Supporting Officer"]}
                    register={register}
                    label="Login As"
                    regFieldName={`login_as`}
                    error={errors?.login_as?.message}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.email?.message}
                    label={`${
                      typeOfApplicant === "Company"
                        ? "Email Address of Contact"
                        : "Email Address"
                    }`}
                    regFieldName="email"
                    placeholder="Email"
                    type="text"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    isRequired={
                      !watch("cell_phone_user") ? "This field is required" : false
                    }
                    isDisabled={true}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.cell_phone_user?.message}
                    label="Cell Phone"
                    regFieldName="cell_phone_user"
                    placeholder="Cell Phone"
                    type="text"
                    validations={{
                      pattern: {
                        value: telephonePattern,
                        message: telephoneNotValid,
                      },
                    }}
                    isRequired={
                      !watch("email") ? "This field is required" : false
                    }
                    onChange={formatPhoneNumber}
                    isDisabled={true}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.password?.message}
                    label="Password"
                    regFieldName="password"
                    placeholder="Password"
                    type="password"
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.reenterpassword?.message}
                    label="Reenter Password"
                    regFieldName="reenterpassword"
                    placeholder="Reenter Password"
                    type="password"
                    fieldClassName="form-control form-control-sm"
                  />
                </div>
              </div>

              <div className="d-flex mb-3 justify-content-between align-item-center" style={reviewSectionStyles}>
                <button
                  className="btn btn-secondary min-200"
                  type="button"
                  onClick={() => {
                    setSelectionCategory(1);
                  }}
                >
                  Back
                </button>

                <button className="btn btn-primary min-200" type="submit">
                  {SUBMIT_TEXT}
                </button>
              </div>
            </form>
          </fieldset>
        )}

      {selectionCategory.applicant_type === "Company" &&
        selectionCategory.show && (
          <fieldset className="flex-wrap justify-content-center full-width">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={formStylingBeforeReviewSection}
            >
              <div className="shadow  bg-white rounded registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Registration Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap  flex-column mb-2 ">
                  <div className="d-flex-wrap  registration-margin justify-content-between">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Reg. #"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration #"
                      isRequired={false}
                      isDisabled={true}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.taxation_registration_number?.message}
                      label="TRN"
                      regFieldName="taxation_registration_number"
                      placeholder="TRN"
                      isRequired={false}
                      isDisabled={true}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                      validations={{
                        pattern: {
                          value: trnPattern,
                          message: trnNotValid,
                        },
                      }}
                      onChange={formatTRN}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.domicile?.message}
                      label="Nationality"
                      regFieldName="domicile"
                      options={["Local", "Foreign"]}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.category_of_applicant?.message}
                      label="Category of Applicant"
                      regFieldName="category_of_applicant"
                      options={["Commercial", "Recreational", "Temporary"]}
                      fieldClassName="form-control form-control-sm"
                      parentClass={"individual-field-margin min-200 max-200"}
                    />
                  </div>
                </div>
              </div>

              <div className="shadow  bg-white rounded registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Company Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
                  <FormHookInput
                    register={register}
                    error={errors?.company_name?.message}
                    label="Company Name"
                    regFieldName="company_name"
                    placeholder="Company Name"
                    type="text"
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookSelect
                    label="Country of Origin"
                    register={register}
                    error={errors?.country_of_origin?.message}
                    regFieldName="country_of_origin"
                    options={countries}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.date_of_registration?.message}
                    label="Date of Registration/Incorporation"
                    regFieldName="date_of_registration"
                    type="date"
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.company_email_address?.message}
                    label="Company Email Address"
                    regFieldName="company_email_address"
                    placeholder="Email"
                    type="text"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.cell_phone?.message}
                    label="Company Phone Number"
                    regFieldName={`cell_phone`}
                    placeholder="Cell Phone"
                    type="text"
                    validations={{
                      pattern: {
                        value: telephonePattern,
                        message: telephoneNotValid,
                      },
                    }}
                    onChange={formatPhoneNumber}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.website?.message}
                    label="Company Website"
                    regFieldName={`website`}
                    placeholder="Company Website"
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookSelect
                    label="Parish"
                    register={register}
                    error={errors?.parish?.message}
                    regFieldName="parish"
                    options={parishList}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookTextArea
                    register={register}
                    error={errors?.address?.message}
                    label="Company Address"
                    regFieldName="address"
                    placeholder="Company Address"
                    cols={61}
                    rows={1}
                    // fieldClassName="form-control form-control-sm"
                    parentClass="individual-field-text-area full-width-flex"
                  />
                </div>
              </div>

              <div className="shadow rounded bg-white registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Contact Details (Company Contact Details)
                </div>
                <HorizontalLine></HorizontalLine>

                {fields?.map((group, groupIndex) => {
                  return (
                    <div key={group.id}>
                      <div className="d-flex-wrap gap-20-10 flex-row justify-content-start registration-margin-left">
                        <FormHookInput
                          register={register}
                          error={
                            errors?.contact_details?.[groupIndex]
                              ?.name_of_contact?.message
                          }
                          label="Name of Contact"
                          regFieldName={`contact_details[${groupIndex}].name_of_contact`}
                          placeholder="Name of Contact"
                          type="text"
                          fieldClassName="form-control form-control-sm"
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors?.contact_details?.[groupIndex]?.cell_phone
                              ?.message
                          }
                          label="Cell Phone"
                          regFieldName={`contact_details[${groupIndex}].cell_phone`}
                          placeholder="Cell Phone"
                          type="text"
                          validations={{
                            pattern: {
                              value: telephonePattern,
                              message: telephoneNotValid,
                            },
                          }}
                          onChange={formatPhoneNumber}
                          fieldClassName="form-control form-control-sm"
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors?.contact_details?.[groupIndex]
                              ?.contact_email_address?.message
                          }
                          label="Contact Email Address"
                          regFieldName={`contact_details[${groupIndex}].contact_email_address`}
                          placeholder="Contact Email"
                          type="text"
                          validations={{
                            pattern: {
                              value: emailPattern,
                              message: emailNotValid,
                            },
                          }}
                          // isRequired={false}
                          fieldClassName="form-control form-control-sm"
                        />

                        <div>
                          <button
                            style={{ marginTop: "1.8rem" }}
                            className=" btn btn-warning"
                            onClick={() => {
                              remove(groupIndex);
                            }}
                            disabled={fields?.length === 1}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="d-flex-wrap mt-3 justify-content-end">
                  <button
                    type="button"
                    onClick={() => {
                      append({ id: Math.random() });
                    }}
                    className="ml-2 btn btn-sm btn-success mr-4"
                  >
                    Add Another Contact
                  </button>
                </div>
              </div>

              {ApplicantDetails}
              {SupportingOfficer}

              <div className="shadow bg-white rounded registration-border mb-3 pb-3">
                <div className="sub-form-header-registration">
                  Login Details
                </div>
                <HorizontalLine></HorizontalLine>

                <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
                  {/* <FormHookSelect
                    register={register}
                    error={errors?.applying_as?.message}
                    label="Applying As"
                    regFieldName="applying_as"
                    options={["Applicant", "Company on Behalf of Crew Member"]}
                    fieldClassName="form-control form-control-sm"
                  /> */}

                  <FormHookRadioInput
                    fieldClassName="d-flex-wrap flex-column"
                    options={["Applicant", "Supporting Officer"]}
                    register={register}
                    label="Login As"
                    regFieldName={`login_as`}
                    error={errors?.login_as?.message}
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.email?.message}
                    label={`${
                      typeOfApplicant === "Company"
                        ? "Email Address of Contact"
                        : "Email Address"
                    }`}
                    regFieldName="email"
                    placeholder="Email"
                    type="text"
                    validations={{
                      pattern: {
                        value: emailPattern,
                        message: emailNotValid,
                      },
                    }}
                    isRequired={
                      !watch("cell_phone_user") ? "This field is required" : false
                    }
                    fieldClassName="form-control form-control-sm"
                  />

                  {/* <FormHookInput
                    register={register}
                    error={errors?.name_of_applicant?.message}
                    label="Name"
                    regFieldName="name_of_applicant"
                    placeholder="Name"
                    type="text"
                    fieldClassName="form-control form-control-sm"
                  /> */}

                  <FormHookInput
                    register={register}
                    error={errors?.cell_phone_user?.message}
                    label="Cell Phone"
                    regFieldName="cell_phone_user"
                    placeholder="Cell Phone"
                    type="text"
                    validations={{
                      pattern: {
                        value: telephonePattern,
                        message: telephoneNotValid,
                      },
                    }}
                    isRequired={
                      !watch("email") ? "This field is required" : false
                    }
                    onChange={formatPhoneNumber}
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.password?.message}
                    label="Password"
                    regFieldName="password"
                    placeholder="Password"
                    type="password"
                    fieldClassName="form-control form-control-sm"
                  />

                  <FormHookInput
                    register={register}
                    error={errors?.reenterpassword?.message}
                    label="Reenter Password"
                    regFieldName="reenterpassword"
                    placeholder="Reenter Password"
                    type="password"
                    fieldClassName="form-control form-control-sm"
                  />
                </div>
              </div>

              <div className="d-flex mb-3 justify-content-between align-item-center"  style={reviewSectionStyles}>
                <button
                  className="btn btn-secondary min-200"
                  type="button"
                  onClick={() => {
                    setSelectionCategory(1);
                  }}
                >
                  Back
                </button>

                <button className="btn btn-primary min-200" type="submit">
                  {SUBMIT_TEXT}
                </button>
              </div>
            </form>
          </fieldset>
        )}
    </div>
  );
};

export default Registration;
